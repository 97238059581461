import React, { useState } from 'react';
import { useEffect } from 'react';
import './css/Turns.css'
import DatePicker from 'react-datepicker';
import { DAYS, DAYS_DICT } from './Turns';
import Switch from './Switch';

const Turn = ({ day, data = [], updateTurns }) => {
  const [breaks, setBreaks] = useState(false);
  const [valid, setValid] = useState(false);
  const turn = useState({});
  const break1 = useState({});
  const break2 = useState({});

  useEffect(() => {
    if (data[0]) {
      turn[1](parseInitial(data[0]));
    }
    if (data.length > 1) setBreaks(true);
    if (data[1]) {
      break1[1](parseInitial(data[1]));
    }
    if (data[2]) {
      break2[1](parseInitial(data[2]));
    }
  }, [])

  const parseInitial = (data) => {
    const start = new Date();
    const end = new Date();
    start.setHours(data.startHour);
    start.setMinutes(data.startMinute);
    end.setMinutes(data.endMinute);
    end.setHours(data.endHour);
    return { start, end };
  }

  const getPayload = (time, type = 'turn') => {
    return {
      type,
      startHour: time.start.getHours(),
      endHour: time.end.getHours(),
      startMinute: time.start.getMinutes(),
      endMinute: time.end.getMinutes()
    }
  }

  useEffect(() => {
    if (turn[0].start && turn[0].end) {
      setValid(true);
      const payload = [getPayload(turn[0])];
      if (break1[0].start && break1[0].end) payload.push(getPayload(break1[0], 'break'))
      if (break2[0].start && break2[0].end) payload.push(getPayload(break2[0], 'break'))
      updateTurns(day, payload);
    }
    else setValid(false);
  }, [turn, valid])

  const TurnInput = ({ data, type = 'turn', breakNumber = 1 }) => {
    const [turn, setTurn] = data;

    const setTime = (key, val) => {
      turn[key] = val;
      setTurn({ ...turn });
    }

    return <div>
      {type === 'break' && <p className="small mb-1 text-primary fw-600">Break #{breakNumber}</p>}
      <div className='d-flex flex-grow-1 turn-time-input'>
        <div>
          <label className="small mb-1">Comienzo del {type === 'turn' ? 'turno' : 'break'}</label>
          <DatePicker
            selected={turn.start}
            className="form-control"
            onChange={(val) => setTime('start', val)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            placeholderText='N/A'
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </div>
        <div>
          <label className="small mb-1">Fin del {type === 'turn' ? 'turno' : 'break'}</label>
          <DatePicker
            selected={turn.end}
            className="form-control"
            onChange={(val) => setTime('end', val)}
            showTimeSelect
            showTimeSelectOnly
            placeholderText='N/A'
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </div>
      </div>
    </div>
  }

  return <div className='turn turn-input'>
    <div>
      <h6>{DAYS_DICT[day]}</h6>
      <div className='d-flex align-items-center pointer' onClick={() => setBreaks(!breaks)}>
        <Switch active={breaks}></Switch>
        <span className='ms-2 mt-1 small'>Incluir breaks</span>
      </div>
    </div>
    <div className='turn-progress'>
      <span className={`lilball ${!valid && 'lilball-invalid'}`}></span>
    </div>
    <div>
      <TurnInput data={turn} />
      {breaks && <TurnInput data={break1} type={'break'} />}
      {breaks && <TurnInput data={break2} type={'break'} breakNumber={2} />}
    </div>
  </div>
}

const TurnsInput = ({ className, data, setData, days }) => {
  const [turns, setTurns] = useState([]);
  const updateTurns = (day, payload) => {
    data[day] = payload;
    setData({ ...data });
  }

  useEffect(() => {
    const renderedTurns = [];
    for (const day of DAYS) {
      if (days.includes(day)) renderedTurns.push(<Turn key={`turn-${day}`} day={day} data={data[day]} updateTurns={updateTurns} />);
      else {
        delete data[day];
        setData({ ...data });
      }
    }
    setTurns(renderedTurns);
  }, [days])

  return (
    <div className={`turn-container ${className}`}>
      {turns}
    </div>
  );
}

export default TurnsInput;