import { RESET, SET_CARD } from "../types/homecards";

export const HOME_CARDS = {
    HOME: 0,
    WAITING_ROOM: 1,
    CONSULTATION_FINISHED: 2,
}
const initialState = {
    current: HOME_CARDS.HOME,
}

export default function homeReducer(state = initialState, action) {
    switch (action.type) {
        case RESET:
            return {
                ...initialState
            };
        case SET_CARD:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}