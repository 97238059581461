import {
    LOADING,
    AUTH_DOCTOR_SUCCESS,
    AUTH_DOCTOR_ERROR,
    GET_DOCTOR_DATA_SUCCESS,
    GET_DOCTOR_DATA_ERROR,
    LOGIN_ERROR,
    SET_PASSWORD_ERROR,
    SET_PASSWORD_SUCCESS,
    LOGIN_DR_SUCCESS,
    LOGIN_DR_ERROR,
    NULL_ERROR,
    LAST_PATIENT,
    CURRENT_PATIENT,
    RESET_CURRENT_PATIENT,
    FINISHED_CONSULTATION,
    RESET_FINISHED_CONSULTATION,
    DOCTOR_METRICS,
    DOCTOR_REGISTRATION,
    DOCTOR_TURN_ACTIVE,
    RESET_USERS
} from '../types/index.js';

const initialState = {
    error: null,
    msg: null,
    authenticated: null,
    isLogged: false,
    turnActive: false,
    user: {
        fullName: null,
        CI: null,
        nacionality: null
    },
    currentPatient: {
        consultation: {}
    },
    lastPatient: {
        consultation: {}
    },
    metrics: {
        currentMonth: {
            totalPatients: 0,
            totalDuration: 0,
        },
        lastMonth: {
            totalPatients: 0,
            totalDuration: 0,
        }
    },
    finishedConsultation: false,
    doctorRegistration: {},
    loading: false
}

export default function usersReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_USERS:
            return {
                ...initialState,
            };
        case LOADING:
            return {
                ...state,
                loading: !state.loading
            };
        case NULL_ERROR:
            return {
                ...state,
                error: null,
                msg: ''
            };
        case AUTH_DOCTOR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                authenticated: action.payload.auth,
                user: {
                    ...state.user,
                    ...action.payload,
                },
                msg: action.payload.msg
            };
        case AUTH_DOCTOR_ERROR:
            return {
                ...state,
                loading: false,
                msg: action.payload,
                error: true
            }
        case LOGIN_ERROR: {
            return {
                ...state,
                authenticated: false
            }
        }
        case LOGIN_DR_SUCCESS:
            return {
                ...state,
                user: action.payload.doctor || action.payload.patient,
                error: false,
                isLogged: true,
                msg: action.payload.msg
            };
        case GET_DOCTOR_DATA_SUCCESS:
            return {
                ...state,
                user: action.payload,
                error: null
            };
        case SET_PASSWORD_SUCCESS:
            return {
                ...state,
                user: action.payload.doctor,
                error: false,
                isLogged: true,
                msg: action.payload.msg
            }
        case LOGIN_DR_ERROR:
            return {
                ...state,
                error: true,
                msg: action.payload
            }
        case SET_PASSWORD_ERROR:
        case GET_DOCTOR_DATA_ERROR:
            return {
                ...state,
                error: true
            }
        case CURRENT_PATIENT:
            return {
                ...state,
                error: false,
                currentPatient: action.payload
            };
        case RESET_CURRENT_PATIENT:
            return {
                ...state,
                error: false,
                currentPatient: { consultation: {} },
            };
        case LAST_PATIENT:
            return {
                ...state,
                error: false,
                lastPatient: action.payload
            };
        case FINISHED_CONSULTATION:
            return {
                ...state,
                error: false,
                finishedConsultation: true,
            };
        case RESET_FINISHED_CONSULTATION:
            return {
                ...state,
                error: false,
                finishedConsultation: false,
            };

        case DOCTOR_TURN_ACTIVE:
            return {
                ...state,
                turnActive: action.payload,
            };

        case DOCTOR_REGISTRATION:
            return {
                ...state,
                doctorRegistration: action.payload,
            };
        case DOCTOR_METRICS:
            return {
                ...state,
                error: false,
                metrics: action.payload || {
                    currentMonth: {
                        totalPatients: 0,
                        totalDuration: 0,
                    },
                    lastMonth: {
                        totalPatients: 0,
                        totalDuration: 0,
                    }
                }
            };
        default:
            return state;
    }
}