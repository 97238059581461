import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import DoctorDashboard from './DoctorDashboard';
import Reports from './Reports';
//Higer order Components

const UserDashboard = () => {
    const { user } = useSelector(state => state.user);
    return <>
        {user.type === 'doctor' && <DoctorDashboard />}
        {user.type !== 'doctor' && <Reports type={user.type} />}
    </>
};

export default UserDashboard