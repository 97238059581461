import {
    GET_REPORT_ERROR,
    GET_REPORT_SUCCESS,
    REPORT_LOADING
} from '../types/index';
import clientAxios from '../config/axios';

export function loading() {
    return dispatch => {
        dispatch({
            type: REPORT_LOADING
        })
    }
};

export function getReport(query) {
    return async function (dispatch) {
        try {
            dispatch(loading());
            const response = await clientAxios.get('/api/reports', { params: query });
            dispatch({
                type: GET_REPORT_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: GET_REPORT_ERROR,
                payload: error.response.data.msg
            });
        }
    }
}

export function getCsvReport(query) {
    return clientAxios.get('/api/reports/csv', query);
}