import React, {useEffect} from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {userAuthenticated} from '../actions/usersActions';
//Higer order Components
const PrivateRoute = ({component:Component,...props}) => {
    const usersReducer = useSelector(state=>state.user);
    const {authenticated,loading,user, isLogged} = usersReducer;

    useEffect(() => {
        userAuthenticated(user.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return ( 
        isLogged ?
        <Outlet/>
        :
        (authenticated === null && !loading) ? <Navigate to='/' /> : 
        <Outlet />
        
     );
}
 
export default PrivateRoute;