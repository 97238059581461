import React, { useEffect, useState } from 'react'
import logo from '../../media/header_logo_primary.svg';
import logoWhite from '../../media/header_logo_secondary.svg';
import { Menu, Headphones, User, Settings, LogOut } from 'react-feather';
import { Helmet } from 'react-helmet';
//datepicker
import "react-datepicker/dist/react-datepicker.css";
import styles from '../css/Dash.module.css'
import patientStyles from '../css/patients.module.css';
import { Outlet, useLocation } from 'react-router-dom';
import useSocket from '../../hooks/useSocket';
import { useDispatch } from 'react-redux';
import { add_patient } from '../../actions/queueActions';
import { add_doctor } from '../../actions/patientActions';

const LIGHT_ROUTES = [
	'/patients/login',
	'/patients/login-register',
	'/patients/recover',
]
const PatientsDashboard = () => {
	const location = useLocation();
	const isLightRoute = () => LIGHT_ROUTES.includes(location.pathname);

	const [socket] = useSocket();
	const dispatch = useDispatch();

	useEffect(() => {
		if (socket) {
			socket.on('colaActualizada', (data) => {
				dispatch(add_patient(data.patients))
				dispatch(add_doctor(data.doctors))
			});
		}
		return () => {
			if (socket) socket.off('colaActualizada');
		}
	}, [socket]);

	return (
		<>
			<section className={"nav-fixed"}>
				<nav className={`${styles.patientsNavbar} topnav navbar navbar-expand justify-content-between justify-content-sm-start navbar-light ${isLightRoute() ? 'bg-transparent' : 'bg-primary'}`} id="sidenavAccordion">
					<a className="navbar-brand pe-3 ps-4 ps-lg-2" >
						<img src={isLightRoute() ? logo : logoWhite} className={styles.logo} alt='' />
					</a>
				</nav>
				<div id="layoutSidenav_content">
					<main>
						<div className={patientStyles.patientsContainer}>
							<Outlet />
						</div>
					</main>
				</div>
			</section>
		</>

	);
}

export default PatientsDashboard;