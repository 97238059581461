import styles from './css/Input.module.css'
import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import check from '../../media/checkJS.svg';
import errorIcon from '../../media/error.svg';
import { ArrowDown, Calendar, ChevronDown } from 'react-feather';
import useOutsideAlerter from '../../hooks/useOutsideClick';
import InputMask from 'react-input-mask';
import { formatNumberThousands, getMonthRange } from '../../helpers';

const monthOptions = [
	{ key: 1, name: 'Enero' },
	{ key: 2, name: 'Febrero' },
	{ key: 3, name: 'Marzo' },
	{ key: 4, name: 'Abril' },
	{ key: 5, name: 'Mayo' },
	{ key: 6, name: 'Junio' },
	{ key: 7, name: 'Julio' },
	{ key: 8, name: 'Agosto' },
	{ key: 9, name: 'Septiembre' },
	{ key: 10, name: 'Octubre' },
	{ key: 11, name: 'Noviembre' },
	{ key: 12, name: 'Diciembre' },
];
const nationalityOptions = [{ key: 'V', name: 'Venezolano', label: 'V' }, { key: 'E', name: 'Extranjero', label: 'E' }];

const CustomInput = ({ name, label, type, error, msg, data, placeholder, setData, className = '', options = [], validators = [], config = {} }) => {
	const inputRef = useRef('');
	const [status, setStatus] = useState('');
	const [opened, setOpened] = useState(false);
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef, setOpened);
	const [pickData, setPickData] = useState({});
	const [dateData, setDateData] = useState([null, null, null]);
	const [ciData, setCiData] = useState(['V', '']);
	const [innerError, setInnerError] = useState('');
	const [dateUpdate, setDateUpdate] = useState(false);

	//effects
	useEffect(() => {
		if (error === false && msg === "Autenticación exitosa") {
			setStatus("border border-success")
			setTimeout(() => {
				setStatus('');
			}, 1000)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error])

	useEffect(() => {
		if (data) {
			switch (type) {
				case 'pick':
					for (const day of data) {
						pickData[day] = true;
					}
					break;
				case 'date':
					if (!dateData[0] && !dateData[1] && !dateData[2]) {
						const date = new Date(data);
						setDateData([date.getMonth() + 1, date.getDate(), date.getFullYear()])
					}
					break;
				case 'ci':
					break;
			}
		}
	}, [data])

	const onBlur = () => {
		if (!['select', 'pick', 'ci'].includes(type) && !config.hideIcon) {
			if (!error && !innerError && data) {
				setStatus("border border-success")
			} else if (error || innerError) {
				setStatus("border border-danger");
			} else {
				setStatus("")
			}
		}
	}

	const setDate = (index, val) => {
		dateData[index] = val;
		setDateData([...dateData]);
	}

	const setCi = (index, val) => {
		ciData[index] = val;
		setCiData([...ciData]);
		const number = Number(ciData[1].split('').filter(e => e !== '.').join(''));
		setData(`${ciData[0]}-${number}`);
	}

	const handleClick = () => {
		if (type === 'select') {
			setOpened(!opened);
		}
	}

	useEffect(() => {
		if (type === 'date') {
			setInnerError('');
			const date = new Date(dateData.join('/'));
			if (isNaN(date.getTime())) {
				setInnerError('Invalid date');
			} else {
				setData(dateData.join('/'));
			}
		}
	}, [dateData])

	const inputData = () => {
		switch (type) {
			case 'select':
				const option = options.find(option => option.key === data) || {}
				const optionLabel = data === 'all' ? 'Todos' : option.label || option.name;
				return <>
					<div className={'col-11 border-0 ' + styles.inputOut}>{optionLabel || placeholder || '--'}</div>
					<ChevronDown />
					{opened && <div className={`card ${styles.modalbox}`}>
						{options.map(option => <div key={option.key} className={`${(data === option.name) || (data === option.label) ? styles.selected : ''}`} onClick={() => setData(option.key)}>{option.name}
						</div>)}
						{!config.hideAll && <div className={`${data === 'all' ? 'selected' : ''}`} onClick={() => setData('all')}>Todos</div>}
					</div>}
				</>
			case 'pick':
				return <>
					<div className="d-flex" style={{ gap: 8 }}>
						{options.map(option =>
							<div key={option.key} onClick={() => {
								if (!config.multiple) {
									setData(option.key);
									const newPickData = { [option.key]: true }
									setPickData({ ...newPickData });
								} else {
									pickData[option.key] = !pickData[option.key];
									setPickData({ ...pickData });
									setData(Object.keys(pickData).filter((key) => pickData[key]));
								}
							}} className={`selectable ${data === option.key || pickData[option.key] ? 'active' : ''}`} style={{ fontSize: 14 }}>{option.name}</div>
						)}
					</div>
				</>
			case 'datepicker':
				let startDate, endDate;
				if (config.selectRange) {
					[startDate, endDate] = data || getMonthRange(new Date());
				} else {
					startDate = data;
				}
				return <>
					<Calendar className='me-2' />
					<DatePicker
						className={'col-11 border-0 ' + styles.inputOut}
						wrapperClassName='bold'
						selectsRange={config.selectRange}
						peekNextMonth
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
						yearItemNumber={100}
						selected={!config.selectRange && data && new Date(data)}
						startDate={startDate}
						endDate={endDate}
						onChange={(update) => {
							setData(update);
						}}
						withPortal
						dateFormat='MMMM dd, yyyy'
						placeholderText='Búsqueda por fecha'
					/>
				</>
			case 'date':
				return <>
					<CustomInput error={innerError} config={{ child: true, hideIcon: true, pattern: '\d*', maxLength: 2 }} className='col-3' data={dateData[1]} setData={(val) => setDate(1, val)}></CustomInput>
					<CustomInput error={innerError} config={{ child: true, hideAll: true }} className='col-5' type="select" options={monthOptions} placeholder="MM" data={dateData[0]} setData={(val) => setDate(0, val)}></CustomInput>
					<CustomInput error={innerError} config={{ child: true, hideIcon: true, pattern: '\d*', maxLength: 4 }} className='col-4' data={dateData[2]} setData={(val) => setDate(2, val)}></CustomInput>
				</>
			case 'ci':
				return <>
					<CustomInput config={{ hideBorders: true, hideAll: true }} type="select" options={nationalityOptions} error={innerError} className='col-2 row' data={ciData[0]} setData={(val) => setCi(0, val)}></CustomInput>
					<div className={styles.inputSeparator}></div>
					<CustomInput placeholder="12.456.789" config={{ hideBorders: true, hideIcon: true }} error={innerError} className='col-10' data={ciData[1]} setData={(val) => setCi(1, formatNumberThousands(val))}></CustomInput>
				</>
			case 'textarea':
				return <textarea
					autoFocus
					value={data}
					name={name}
					rows={config.rows}
					maxLength={config.maxLength}
					pattern={config.pattern}
					min={config.min}
					max={config.max}
					onBlur={onBlur}
					placeholder={placeholder}
					className={'col-11 border-0 ' + styles.inputOut}
					autoComplete='off'
					onChange={handleChange}
				/>
			default:
				return config.mask ?
					<InputMask
						mask={config.mask}
						value={data}
						name={name}
						onBlur={onBlur}
						placeholder={placeholder}
						className={'col-11 border-0 ' + styles.inputOut}
						autoComplete='off'
						onChange={handleChange}
					/> :
					<input
						autoFocus
						value={data}
						name={name}
						type={type}
						maxLength={config.maxLength}
						pattern={config.pattern}
						min={config.min}
						max={config.max}
						onBlur={onBlur}
						placeholder={placeholder}
						className={'col-11 border-0 ' + styles.inputOut}
						autoComplete='off'
						onChange={handleChange}
					/>
		}
	}

	const handleChange = (event) => {
		setData(event.target.value);
	};

	return (<div className={className} >
		{label && <label className="small mb-2" htmlFor={name}>
			{label}
		</label>}
		<div ref={wrapperRef} className={`position-relative ${status} ${['pick', 'date'].includes(type) || config.hideBorders ? '' : `form-control align-middle  ${styles.inputWrapper}`}`}>
			<div style={{ marginRight: '-16px' }} className={`h-100 d-flex align-items-center ${type === 'select' ? 'pointer' : ''} ${type === 'date' ? 'row' : ''}`} onClick={handleClick}>
				{inputData()}
				{!config.hideIcon && type !== 'date' && status === "border border-danger" ? <img className='align-baseline' src={errorIcon} alt='error' /> : null}
				{!config.hideIcon && type !== 'date' && status === "border border-success" ? <img className='align-baseline' src={check} alt='check' /> : null}
			</div>
		</div>
	</div>
	);
}

export default CustomInput;