import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styles from '../css/patients.module.css';
import Button from '../utils/Button';
import CustomInput from '../utils/Input';
import { Info } from 'react-feather';
import { resetHome } from '../../actions/homeActions';
import { setCurrentDoctor } from '../../actions/patientActions';
import useSocket from '../../hooks/useSocket';
import DoctorData from './components/DoctorData';
import nurseImg from "../../media/nurse.png";
import doctorsImg from '../../assets/img/doctors.png';
import DoctorImage from '../utils/DoctorImage';
import { useNavigate } from 'react-router-dom';
import { getConsultationTime } from '../../helpers';

const WaitingRoom = () => {
	const navigate = useNavigate();
	const [socket, socketId] = useSocket();
	const { user } = useSelector(state => state.user);
	const { doctor, consultation, patients } = useSelector(state => state.queue);
	const dispatch = useDispatch();

	useEffect(() => {
		if (socket) {
			let initialName = user.name[0].toUpperCase() + user.lastName[0].toUpperCase();
			socket.emit('usuarioConectado', { id: user._id, usuario: initialName, name: user.name, lastName: user.lastName, nationality: user.nationality, ci: user.ci, consultation });
			socket.on('sendDataDrToPatient', (data) => {
				dispatch(setCurrentDoctor(data.data))
			});
			socket.on('cancelMeetDr', (data) => {
				dispatch(setCurrentDoctor(data.data))
			});
		}
		return () => {
			if (socket) {
				socket.off('sendDataDrToPatient');
				socket.off('usuarioConectado');
			}
		}
	}, [socket]);

	const goToMeet = () => {
		socket.emit('goToMeet', doctor.id);
		return navigate('/patients/meet')
	}

	const handleCancel = () => {
		socket.emit('cancelMeet', doctor.id);
		dispatch(resetHome())
	}

	return (
		<section style={{ alignSelf: 'start' }} className={styles.phoneCard}>
			{!doctor.id ? <>
				<div className={`${styles.insetPhoneCard} patternBg`}>
					<h1 className="primary-dark">Sala de espera</h1>
					<p>En breves momentos uno de nuestros doctores lo atenderá, por favor espere.</p>
					<img className="mt-4" src={doctorsImg} alt='' />
				</div>
				<div className={styles.waitingPatients}>
					<div><span style={{ color: 'white' }}>Pacientes en cola</span></div>
					<h1 className='mt-1 mb-0' style={{ color: 'white', fontSize: 64, lineHeight: '64px' }}>{patients.length}</h1>
				</div>
				<div className='text-center mb-4'>
					<p>Tiempo de espera estimado: {getConsultationTime(patients.length * 5 * 60)}</p>
				</div>
				<Button onClick={() => dispatch(resetHome())} btn="secondary" className='mt-4 mb-0 w-100 primary' text={"Cancelar consulta"}></Button>
			</> : <>
				<div className={`${styles.insetPhoneCard} patternBg`}>
					<DoctorData doctor={doctor} vertical />
					<div style={{ marginBottom: -8 }} >
						<Button onClick={() => goToMeet()} className='mt-5 mb-0 w-100' text={"Ingresar a la consulta"}></Button>
					</div>
				</div>
				<div className='d-flex justify-content-start mt-5 my-4'>
					<DoctorImage size={64} staticSrc={nurseImg} background="#DCE2E2" />
					<div className='ms-3'>
						<h2 className="primary-dark">Gracias por su espera,</h2>
						<p>El doctor está listo para recibirle, por favor ingrese.</p>
					</div>
				</div>
				<Button onClick={handleCancel} btn="secondary" className='mt-4 mb-0 w-100 primary' text={"Cancelar consulta"}></Button>
			</>
			}
		</section>
	);
}

export default WaitingRoom;