import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
//Higer order Components

const Private = ({ redirect = '/', children }) => {
    const {isLogged} = useSelector(state=>state.user);;
    if (!isLogged) {
        return <Navigate to={redirect} />;
    }
    
    return children;
  
  };

  export default Private