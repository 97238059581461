import React, { useEffect, useState } from 'react';
import styles from '../css/patients.module.css'
import nurseImg from "../../media/nurse.png";
import DoctorImage from '../utils/DoctorImage';

const LoginContainer = ({ title, subtitle, size = 466, children }) => {
  return (
    <div style={{maxWidth: size}} className={styles.loginContainer}>
      <div className={styles.floatingNurse}>
        <DoctorImage size={96} staticSrc={nurseImg} background="#DCE2E2" />
      </div>
      <div className="text-center mt-4">
        <h1 className="primary-dark">{title}</h1>
        {subtitle && <p>{subtitle}</p>}
      </div>
      <div>
        {children}
      </div>
    </div>
  );
}

export default LoginContainer;