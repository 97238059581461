//format for inputs, add dots
export const formatNumberThousands = (number) => {
    if (!number) return '';
    const data = number.toString();
    const partsOne = data.split('').filter(e => e !== '.').join('')
    const parts = partsOne.split(".");
    const numberPart = parts[0];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ".");
}

export const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(58|\d{1,2}|)?(\d{3})?(\d{3})?(\d{4})?$/);
    if (match) {
        var intlCode = (match[1] ? '+58' : '');
        return [intlCode, `(${match[2]})`, match[3], match[4], match[5]].join(' ');
    }
    return null;
}

export const getHours = (hours = '', minutes = '') => {
    let aa = 'AM';
    if (hours >= 12) {
        aa = 'PM';
        if (hours >= 13) hours -= 12;
    }
    return `${hours.toString().padStart(2, 0)}:${minutes.toString().padStart(2, 0)} ${aa}`;
};

//generate dates
export const generateDate = (dt = Date.now()) => {
    const date = new Date(dt);
    const options = {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit',
        minute: '2-digit'
    };
    return date.toLocaleString([], options).split(' ');
}

export const generateDatetime = (dt = Date.now()) => {
    const date = new Date(dt);
    const options = { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleString(['es-VE'], options).split(' ');
}

export const getDate = (d) => {
    const date = new Date(d);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString('es-VE', options).split(' ');
}

//set availability
export const evalSchedule = (obj) => {
    //get day:
    let d = new Date();
    const options = { weekday: 'long', hour: 'numeric' }
    let strDate = d.toLocaleDateString('en-US', options).split(' ');
    for (const day in obj) {
        if (day === strDate[0].toLowerCase().slice(0, -1)) {
            if (obj[day].length) {
                const hourOne = obj[day][0].startHour <= 12 ?
                    obj[day][0].startHour + 'AM' :
                    (obj[day][0].startHour - 12) + 'PM';

                const hourTwo = obj[day][0].endHour <= 12 ?
                    obj[day][0].endHour + 'AM' :
                    (obj[day][0].endHour - 12) + 'PM';

                return `${hourOne} a ${hourTwo}`

            }
            return 'Usted no posee un horario para el día de hoy'
        };
    };
};
export const evalHours = (obj) => {
    //generate current time
    let d = new Date().toLocaleTimeString('en-US', { weekday: 'long' }).split(' ')
    let currentDay = d[0].toLowerCase();
    let currentHour = new Date().getHours();
    //here the first position represent the hour and the second position represent the minutes
    //now let's compare this times with the current time, if the current time is not within the range it should them false otherwise the result is true
    for (let day in obj) {
        if (day === currentDay) {
            if (obj[day].length) {
                // if u want, this slice can be removed a include the minutes in the evaluation               
                if (currentHour >= obj[day][0].startHour && currentHour <= obj[day][0].endHour) {
                    return false;
                }
                return true;
            }
            return true;
        }
    }

}

export const getConsultationTime = (seconds) => {
    const time = Math.round(seconds / 1000);
    const totalMinutes = Math.round(time / 60);
    const remainingMinutes = totalMinutes % 60;
    const hours = (totalMinutes - remainingMinutes) / 60;
    if (totalMinutes === 0 && hours === 0) return `${time} segundo${time !== 1 ? 's' : ''}`;
    return `${hours ? `${hours} hora${hours !== 1 ? 's' : ''}` : ''}${(hours && remainingMinutes) ? ', ' : ''}${remainingMinutes ? `${remainingMinutes} minuto${remainingMinutes !== 1 ? 's' : ''}` : ''}`;
}

export const getDateShort = (d) => {
    const date = new Date(d);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString('es-VE', options).split(' ');
}

export const getDateFull = (date) => {
    const monthName = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const bday = new Date(date);
    return `${bday.getDate()} de ${monthName[bday.getMonth()]}, ${bday.getFullYear()}`;
}

export const getDateTime = (date) => {
    const dt = new Date(date);
    return `${getDate(date)}- ${dt.getHours().toString().padStart(2, 0)}:${dt.getMinutes().toString().padStart(2, 0)}`;
}

export const consultationTime = (endDate) => {
    const date = new Date(endDate);
    const diff = Date.now() - date.getTime();
    const hoursPassed = diff / (1000 * 60 * 60)

    if (hoursPassed < 1) {
        const time = Math.floor(hoursPassed * 60);
        return `Hace ${time} minuto${time !== 1 ? 's' : ''}`;
    } else {
        if (hoursPassed < 24) {
            const time = Math.floor(hoursPassed);
            return `Hace ${time} hora${time !== 1 ? 's' : ''}`;
        }
    }
    return getDateShort(endDate);
}

export const getAge = (date) => {
    const bday = new Date(date);
    const diff = Date.now() - bday.getTime();
    const dateAge = new Date(diff);
    const age = Math.abs(dateAge.getUTCFullYear() - 1970);
    return `${age} ${age === 1 ? 'año' : 'años'}`;
}



export const getMonthRange = (date) => {
    const now = new Date(date);
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    now.setMonth(now.getMonth() + 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    return [startOfMonth, endOfMonth];
}