import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { formatNumberThousands, formatPhoneNumber, getAge, getDateFull } from '../../helpers';
import Button from '../utils/Button';
import DoctorImage from '../utils/DoctorImage';
import styles from '../css/patients.module.css';

// css
const Profile = () => {
	const { user } = useSelector(state => state.user);

	const logout = () => {
		localStorage.clear();
		window.location.href = `/patients`;
	}

	return (
		<section className={`d-flex flex-column ${styles.profileCard}`}>
			<div className='d-flex justify-content-center flex-column align-items-center' >
				<DoctorImage userId={user._id} editable size={96} src={user.photo} gender={user.gender} />
				<h2 className='primary-dark mt-3 mb-0'>{user.name + ' ' + user.lastName}</h2>
				<p>{user.nationality}-{formatNumberThousands(user.ci.toString())}</p>
			</div>
			<div className="mt-5">
				<span >Fecha de nacimiento</span>
			</div>
			<p><span className='primary-dark fw-800'>{getDateFull(user.birthDate)}</span> <span>— {getAge(user.birthDate)}</span></p>
			<div className="mt-5"><span >Correo electrónico</span></div>
			<div><span className='primary-dark fw-800'>{user.email}</span></div>
			<div className="mt-5"><span >Teléfono de contacto</span></div>
			<div><span className='primary-dark fw-800'>{formatPhoneNumber(user.numberPhone)}</span></div>
			<div className='flex-grow-1'/>
			<Button onClick={logout} className='mt-4 mb-0 w-100' btn="danger" text={"Cerrar sesión"}></Button>
		</section>
	);
}

export default Profile;