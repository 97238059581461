import {
    REPORT_LOADING,
    GET_REPORT_SUCCESS,
    GET_REPORT_ERROR
} from '../types/index.js';

const initialState = {
    loading: false,
    error: undefined,
    report: undefined,
}

export default function reportReducer(state = initialState, action) {
    switch (action.type) {
        case REPORT_LOADING:
            return {
                ...state,
                loading: true,
                error: undefined,
                report: undefined
            };
        case GET_REPORT_SUCCESS:
            return {
                ...state,
                error: undefined,
                report: action.payload.data,
                loading: false
            };
        case GET_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                report: undefined
            }
        default:
            return state;
    }
}