import React, {useEffect} from 'react';
import styles from './modalsCSS/Modal.module.css';
import stylesModal from './modalsCSS/ModalFinishTurn.module.css';
import {X} from 'react-feather'
import useSocket from '../../../hooks/useSocket';
function ModalFinishTurn({setShowModal,showModal,setTurnActive,title,body}) {
  const [socket] = useSocket();

	const endTurn = () => {
		socket.emit('endTurn');
    setTurnActive(false);
    console.log('terminar turno')
    setShowModal({...showModal,modalFinish:false});
	}

  const closeOnEscapeKeyDown = e => {
    if((e.charCode || e.keyCode) === 27){
      setShowModal({...showModal,modalFinish:false})
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown)
    return function cleanup(){
      document.body.removeEventListener('keydown',closeOnEscapeKeyDown)
    }
  },[])

      return (

      <div className={`${styles.modal} ${stylesModal.modalF} ${showModal.modalFinish && styles.animation}`} onClick={() => setShowModal(false)}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <div className={`${styles.modalHeader} d-flex flex-row justify-content-between`}> 
          <h4 className={`${styles.modalTitle} text-start`}>
              <b>{title}</b>
          </h4>
          <div className='btn m-0 p-0'>
            <X 
            color={'black'}
            
            style={{ verticalAlign: 'middle' }}
            onClick={() => setShowModal({...showModal,modalFinish:false})}
            />
          </div>
        </div>

        <div className={`${styles.modalBody} d-flex justify-content-center flex-column`}>
         <p className='my-0 text-start'>Hoy has estado en tu turno <strong className='text-dark'>{body}</strong>.</p><br />
         <p className='my-0 text-start'>¿Estás seguro de que quieres terminar tu turno por hoy?</p>
        </div>

        <div className={`${styles.modalFooter} d-flex flex-row justify-content-end`}>
          <button 
          className='btn btn-primary mx-2'
          onClick={() => setShowModal({...showModal,modalFinish:false})}>
           Volver a la sala de espera
          </button>
          <button 
          className='btn btn-outline-primary'
          onClick={endTurn}>
            Terminar turno
          </button>
        </div>
          
      </div>
    </div>
    );
}

export default ModalFinishTurn;