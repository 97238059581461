import React, { useEffect, useState } from "react";
import { Star } from "react-feather";
import styles from '../../css/Dash.module.css'

//media
//dimensions

const Rating = ({ rating, setRating, showText, justify = 'center', small = false }) => {
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(<div className={styles.rating} style={{ paddingInline: small ? 3 : 9 }} key={i} onClick={() => { if (setRating) setRating(i + 1) }}>
        <Star width={small ? 16 : 26} height={small ? 16 : 26} className={rating > i ? styles.starActive : styles.star} />
      </div>)
    }
    return stars;
  }

  const getStarText = (rating) => {
    switch (rating) {
      case 1: return 'Muy malo'
      case 2: return 'Malo'
      case 3: return 'Regular'
      case 4: return 'Bueno'
      case 5: return 'Excelente'
    }
  }

  return (<div style={{ textAlign: 'center'}}>
    <div className={styles.stars}>{renderStars()}</div>
    { showText && <p className="mt-3">{getStarText(rating)}</p> }
  </div >);
}

export default Rating;