import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import './css/Doctors.css'
import { formatNumberThousands, getHours } from '../helpers';
import CustomInput from './utils/Input';
import PageTitle from './utils/PageTitle';
import Tag from './utils/Tag';
import TitleCard from './utils/TitleCard';
import { useDispatch } from 'react-redux';
import { getDoctors, getMetrics, setDoctorRegistration, updateDoctor } from '../actions/usersActions';
import { Link } from 'react-router-dom';
import DoctorImage from './utils/DoctorImage';
import { DAYS_DICT, DAYS_SUN_ZERO } from './utils/Turns';

const customStyles = {
    headCells: {
        style: {
            fontFamily: "Metropolis",
            color: "#69707A",
            fontWeight: '700',
            padding: '12px', // override the row height
        },
    },
    cells: {
        style: {
            fontFamily: "Metropolis",
            color: "#69707A",
            padding: '12px', // override the row height
        },
    },
};

const paginationComponentOptions = {
    rowsPerPageText: 'Doctores por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

const Doctors = () => {
    const dispatch = useDispatch();
    const [doctors, setDoctors] = useState([]);
    const [filter, setFilter] = useState('');
    const [filteredDoctors, setFilteredDoctors] = useState('');
    const [filterStatus, setFilterStatus] = useState('all');

    const toggleStatus = async (row) => {
        const update = row.status === 'active' ? 'inactive' : 'active';
        await updateDoctor(row._id, { status: update });
        searchDoctors();
    }
    const DoctorName = ({ row }) => {
        return <div className='d-flex align-items-center'>
            <DoctorImage size={'2.5rem'} src={row.photo} gender={row.gender} />
            <div className="ms-2">
                <div className="mb-1 text-gray-900"><b>{row.name} {row.lastName}</b></div>
                <div>{formatNumberThousands(row.ci.toString())}</div>
            </div>
        </div>
    }

    const ReportTurns = ({ row }) => {
        if (!row.availability) return <div className="text-gray-900"><b>Sin turnos</b></div>
        const today = (new Date()).getDay();
        let nextDay;
        for (let i = 0; i < 7; i++) {
            const day = DAYS_SUN_ZERO[(today + i) % 7];
            if (row.availability[day]) {
                nextDay = day;
                break;
            }
        }
        return <div>
            <div className="text-gray-900 mb-3"><b>{nextDay === DAYS_SUN_ZERO[today] ? 'Hoy' : DAYS_DICT[nextDay]}</b></div>
            <ul>
                {row.availability[nextDay].map(({ type, startHour, endHour, startMinute, endMinute }) => {
                    return <li key={row._id + startHour}>
                        <div className='d-flex flex-grow-1 mb-2'>
                            <div><span>{type === 'break' ? 'Break: ' : ''}</span> {getHours(startHour, startMinute)}</div>
                            <div>&nbsp;-&nbsp;</div>
                            <div>{getHours(endHour, endMinute)}</div>
                        </div>
                    </li>
                })}
            </ul>
        </div>
    }

    const columns = [
        {
            name: 'Médico',
            minWidth: '200px',
            selector: row => row.name,
            cell: row => <DoctorName row={row} />,
            sortable: true,
        },
        {
            name: 'Estatus',
            selector: row => row.status,
            cell: row => <Tag
                className={row.status === 'active' ? '' : 'inactive'}
                title={row.status === 'active' ? 'Activo' : 'Inactivo'}
            />,
            center: true,
            sortable: true,
        },
        {
            name: 'Siguiente turno',
            minWidth: '220px',
            selector: row => row.startDate,
            cell: row => <ReportTurns row={row} />,
            sortable: true,
        },
        {
            name: 'Calificación',
            selector: row => row.rating || 0,
            cell: row => <Tag className="rating" title={`${row.rating || 1}/5`} />,
            center: true,
            sortable: true,
        },
        {
            name: 'Acciones',
            minWidth: '300px',
            cell: row => <div className="d-flex action-btns" style={{ gap: 8 }}>
                <Link to={`/dashboard/doctors/` + row._id}>
                    <div className='btn btn-primary'>Ver detalle</div>
                </Link>
                <Link to={`/dashboard/register/${row._id}`}>
                    <div className='btn edit'>Editar</div>
                </Link>
                <div onClick={() => toggleStatus(row)} className={`btn ${row.status === 'active' ? 'btn-outline-danger' : 'btn-primary'}`}>{row.status === 'active' ? 'Desactivar' : 'Activar'}</div>
            </div>,
            sortable: true,
        },
    ];

    useEffect(() => {
        const search = filter.toLowerCase();
        setFilteredDoctors(doctors.filter(({ name = '', lastName = '', ci = '' }) => {
            return name.toLowerCase().includes(search) || lastName.toLowerCase().includes(search) || ci.toString().includes(search);
        }));
    }, [filter])

    useEffect(() => {
        searchDoctors();
    }, [filterStatus])

    const searchDoctors = async () => {
        const doctors = await getDoctors('', { status: filterStatus });
        setDoctors(doctors.data.data || []);
        setFilteredDoctors(doctors.data.data || []);
    }

    return <>
        <PageTitle title="Médicos">
            <Link to="/dashboard/register">
                <button className="btn btn-primary btn-block" onClick={() => dispatch(setDoctorRegistration({}))}> Registrar nuevo médico </button>
            </Link>
        </PageTitle>
        <TitleCard title={'Lista de médicos'}>
            <div className="d-flex justify-content-between align-items-sm-center flex-column flex-sm-row mb-4">
                <div className="d-flex" style={{ gap: 8 }}>
                    <div onClick={() => setFilterStatus('all')} className={`selectable ${filterStatus === 'all' && 'active'}`}>Todos</div>
                    <div onClick={() => setFilterStatus('active')} className={`selectable ${filterStatus === 'active' && 'active'}`}>Activos</div>
                    <div onClick={() => setFilterStatus('inactive')} className={`selectable ${filterStatus === 'inactive' && 'active'}`}>Inactivos</div>
                </div>
                <div>
                    <CustomInput placeholder={'Buscar médico...'} data={filter} setData={setFilter} />
                </div>
            </div>
            <div>
                <DataTable
                    customStyles={customStyles}
                    paginationComponentOptions={paginationComponentOptions}
                    pagination
                    columns={columns}
                    data={filteredDoctors}
                />
            </div>
        </TitleCard>
    </>
};

export default Doctors