import React from 'react';
import './css/Switch.css'
const Switch = ({ active = false}) => {
    return ( 
      <div className={`switch ${active ? 'active' : ''}`}>
        <div className="switch-ball"></div>
      </div>
    );
}
 
export default Switch;