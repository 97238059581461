import React, { useState } from 'react'
import imgLogo from '../media/logo_header.png';
import { Menu, Headphones, User, Settings, LogOut } from 'react-feather';
import { Helmet } from 'react-helmet';
//datepicker
import "react-datepicker/dist/react-datepicker.css";
import styles from './css/Dash.module.css'
//redux
import { useSelector } from 'react-redux';
//navigations
import { Link, Outlet, useLocation } from 'react-router-dom';
import DoctorImage from './utils/DoctorImage';

const Dashboard = () => {
	const { user } = useSelector(state => state.user);
	const location = useLocation();

	const [sidenavToggled, setsidenavToggled] = useState(true)

	const logout = () => {
		localStorage.clear();
		window.location.href = `/${user.type !== 'doctor' ? user.type : ''}`;
	}

	return (
		<>
			<section className={sidenavToggled ? "nav-fixed" : "nav-fixed sidenav-toggled"}>
				<nav className="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white" id="sidenavAccordion">
					{/* <!-- Sidenav Toggle Button--> */}
					<button onClick={() => setsidenavToggled(!sidenavToggled)} className="btn btn-icon btn-transparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0" id="sidebarToggle"><Menu /></button>
					{/* <!-- Navbar Brand-->
			<!-- * * Tip * * You can use text or an image for your navbar brand.-->
			<!-- * * * * * * When using an image, we recommend the SVG format.-->
			<!-- * * * * * * Dimensions: Maximum height: 32px, maximum width: 240px--> */}
					<a className="navbar-brand pe-3 ps-4 ps-lg-2" href={'#'}>
						<img src={imgLogo} className={styles.logo} alt='' />
					</a>

					{/* <!-- Navbar Items--> */}
					<ul className="navbar-nav align-items-center ms-auto">
						{/* <!-- Documentation Dropdown--> */}

						{/* <!-- User Dropdown--> */}
						<li className="nav-item dropdown no-caret dropdown-user me-3 me-lg-4">
							<a
								href
								className="btn btn-icon btn-transparent-dark dropdown-toggle"
								id="navbarDropdownUserImage"
								role="button"
								data-bs-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false">
								<DoctorImage size={40} src={user.photo} gender={user.gender} />
							</a>
							<div className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">
								<h6 className="dropdown-header d-flex align-items-center">
									<DoctorImage className="dropdown-user-img" size={40} src={user.photo} gender={user.gender} />
									<div className="dropdown-user-details ms-2">
										<div className="dropdown-user-details-name">{user.name} {user.lastName}</div>
										<div className="dropdown-user-details-email">{user.email}</div>
									</div>
								</h6>
								<div className="dropdown-divider"></div>
								<a className="dropdown-item pointer" onClick={logout}>
									<div className="dropdown-item-icon"><LogOut /></div>
									Cerrar sesión
								</a>
							</div>
						</li>
					</ul>
				</nav>
				<div id="layoutSidenav">
					<div id="layoutSidenav_nav">
						<nav className="sidenav shadow-right sidenav-light">
							<div className="sidenav-menu">
								<div className="nav accordion" id="accordionSidenav">
									{/* <!-- Sidenav Heading (Addons)--> */}
									<div className="sidenav-menu-heading">CONSULTA VIRTUAL</div>
									{/* <!-- Sidenav Link (Charts)--> */}
									<Link className={`nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`} to='/dashboard'>
										<div className="nav-link-icon"><Headphones /></div>
										{user.type === 'insurance' ? 'Reporte' : 'Consulta'}
									</Link>
									{/* <!-- Sidenav Link (Tables)--> */}
									{user.type === 'admin' && <Link className={`nav-link ${location.pathname === '/dashboard/doctors' ? 'active' : ''}`} to='/dashboard/doctors'>
										<div className="nav-link-icon"><User /></div>
										Médicos
									</Link>}
								</div>
							</div>
							{/* <!-- Sidenav Footer--> */}
							<div className="sidenav-footer">
								<div className="sidenav-footer-content">
									<div className="sidenav-footer-subtitle">Sesión iniciada de:</div>
									<div className="sidenav-footer-title">{user.gender === 'M' ? 'Dr.' : 'Dra.'} {user.name} {user.lastName}</div>
								</div>
							</div>
						</nav>
					</div>

					<div id="layoutSidenav_content">
						<main>
							<div className="container-xl px-4 mt-5">
								<Outlet />
							</div>
						</main>
					</div>
				</div>
			</section>
		</>

	);
}

export default Dashboard;