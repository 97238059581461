import React, { useState, useEffect } from 'react'
// css
import styles from '../css/patients.module.css';
import Spinner from './../utils/Spinner';
// redux and react router dom
import { Link, useNavigate } from 'react-router-dom';
import { formatNumberThousands } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setCiUser, nullError, authLogin, loginPass, registerNewUserByCi, loginWithPassword, setPasswordRedux, resetUsers } from '../../actions/usersActions';
import LoginContainer from './LoginContainer';
import CustomInput from '../utils/Input';
import Button from '../utils/Button';
import { Check, ChevronLeft } from 'react-feather';
import { showInfoModal } from '../../actions/modalActions';
import { resetCurrentDoctor } from '../../actions/patientActions';
import ErrorMessage from './components/ErrorMessage';

const LoginOrRegister = () => {
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const { loading, isLogged, authenticated, user, msg } = useSelector(state => state.user);
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const [disabledBtn, setDisabledBtn] = useState(true);
	const [isRegistering, setIsRegistering] = useState(false);

	const goBack = () => {
		navigate("/patients/login");
	}

	const goBackClear = () => {
		dispatch(resetUsers());
		goBack();
	}

	useEffect(() => {
		if (isLogged) navigate("/dashboard")
	}, [])

	useEffect(() => {
		setDisabledBtn(!password);
	}, [password])

	useEffect(() => {
		if (user._id && isLogged) {
			navigate('/patients');
			if (isRegistering)
				dispatch(showInfoModal(
					'Registro exitoso',
					`Bienvenid${user.gender === 'M' ? `o Sr ${user.name}` : `a Sra ${user.name}`} al servicio de atención virtual de Compañía Seguros.`
				));
		}
	}, [user, isLogged])

	const validations = [
		(data) => data.length >= 6,
		(data) => /\d+/.test(data),
		(data) => /[A-Za-z]+/.test(data),
	]

	const handleSubmit = async e => {
		e.preventDefault();
		const payload = { id: user.id, password };
		if (authenticated) {
			dispatch(loginWithPassword(payload, false));
		} else {
			setIsRegistering(true);
			dispatch(setPasswordRedux(payload))
		}
	};


	const PasswordCondition = ({ validation, text }) => {
		return <div className='d-flex align-items-center'>
			<div className={`${styles.conditionCheck} ${validation(password) && 'bg-primary'}`}>
				<Check stroke={'white'} />
			</div>
			<p>{text}</p>
		</div>
	}

	return (
		<section>
			<div id="layoutAuthentication">
				<div id="layoutAuthentication_content">
					<main>
						{authenticated ? <>
							<LoginContainer size={564} title={`Bienvenid${user.gender === 'M' ? `o Sr ${user.name}` : `a Sra ${user.name}`}`} subtitle={"Ingresa su contraseña para poder acceder a la \naplicación."}>
								{/* <!-- Basic login form--> */}
								{/* <!-- Login form--> */}
								<form onSubmit={handleSubmit}>
									<div className="offset-2 col-8">
										<div className="my-4">
											<CustomInput
												type="password" validations={validations}
												label="Contraseña" data={password} setData={(val) => setPassword(val)}></CustomInput>
											<div className='text-center mt-2 text-decoration-underline'>
												<Link to="/patients/recover">Olvidé mi contraseña</Link>
											</div>
										</div>
										<ErrorMessage message={msg} />
									</div>
									<div className='d-flex justify-content-between align-items-center'>
										<Button onClick={goBackClear} className='mt-4 mb-0 ps-0' btn={"transparent"} text={<><ChevronLeft /><p className='ps-3'>Volver al inicio</p></>}></Button>
										<Button type='submit' className='mt-4 mb-0' loading={loading} disabled={!password} text={"Ingresar"}></Button>
									</div>
								</form>
							</LoginContainer>
						</> : <>
							<LoginContainer size={684} title="Nueva contraseña" subtitle={"Por favor cree una contraseña con la que podrá ingresar."}>
								{/* <!-- Basic login form--> */}
								{/* <!-- Login form--> */}
								<form onSubmit={handleSubmit}>
									<div className={styles.registerContainer}>
										<div>
											<CustomInput type="password" validations={validations}
												label="Contraseña" data={password} setData={(val) => setPassword(val)}></CustomInput>
											<CustomInput className="mt-3" type="password" validations={validations}
												label="Confirmación de contraseña" data={passwordConfirmation} setData={(val) => setPasswordConfirmation(val)}></CustomInput>
										</div>
										<div className={styles.conditionList}>
											<PasswordCondition validation={validations[0]} text="Mínimo 6 caracteres" />
											<PasswordCondition validation={validations[2]} text="Al menos una letra" />
											<PasswordCondition validation={validations[1]} text="Al menos un número" />
										</div>
									</div>
									<div className='d-flex justify-content-between align-items-center'>
										<Button onClick={goBack} className='mt-4 mb-0 ps-0' btn={"transparent"} text={<><ChevronLeft /><p className='ps-3'>Volver al inicio</p></>}></Button>
										<Button type="submit" className='mt-4 mb-0' loading={loading} disabled={disabledBtn} text={"Guardar e ingresar"}></Button>
									</div>
								</form>
							</LoginContainer>
						</>}
					</main>
				</div>
			</div >
		</section >
	);
}

export default LoginOrRegister;