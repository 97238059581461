import React, { useState, useEffect, useRef, useCallback } from 'react';
import useSocket from '../hooks/useSocket';
import styles from './css/StreamArea.module.css';
import useMediaStream from '../hooks/useMediaStream';
import { Mic, MicOff, Phone, Video, VideoOff } from 'react-feather';
import { useSelector } from 'react-redux';

const VideoCall = ({ callId, onEndStream, isCaller = false }) => {
  const [remoteStream, setRemoteStream] = useState(null);
  const [isCallStarted, setIsCallStarted] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const { consultation } = useSelector(state => state.queue);

  const [socket, socketId] = useSocket();
  // LOCAL STREAM
  const { stream, hasAudio, hasVideo, setAudio, setVideo, stopStream } = useMediaStream();

  const peerConnectionRef = useRef(null);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);


  /* EXPRESS TURN SERVERS /*
  const servers = {
    iceServers: [
      { urls: 'stun:stun.l.google.com:19302' },
      { urls: 'stun:stun1.l.google.com:19302' },
      {
        urls: "turn:relay1.expressturn.com:3478",
        username: "ef7NFWSA0C4WZW6DHM",
        credential: "Zpf1Mns2wTHQm9lM",
      },
    ]
  }
  */
  const servers = {
    iceServers: [
      {
        urls: "stun:stun.relay.metered.ca:80",
      },
      {
        urls: "turn:global.relay.metered.ca:80",
        username: "0801073745f56d2683c6c268",
        credential: "U7O7bg1QueovHCgD",
      },
      {
        urls: "turn:global.relay.metered.ca:80?transport=tcp",
        username: "0801073745f56d2683c6c268",
        credential: "U7O7bg1QueovHCgD",
      },
      {
        urls: "turn:global.relay.metered.ca:443",
        username: "0801073745f56d2683c6c268",
        credential: "U7O7bg1QueovHCgD",
      },
      {
        urls: "turns:global.relay.metered.ca:443?transport=tcp",
        username: "0801073745f56d2683c6c268",
        credential: "U7O7bg1QueovHCgD",
      },
    ],
  };

  useEffect(() => {
    // Set up socket event listeners
    if (socket) {
      socket.on('v2-offer', handleOffer);
      socket.on('v2-answer', handleAnswer);
      socket.on('v2-ice-candidate', handleICECandidate);
      socket.on('v2-reconnect-offer', handleReconnectOffer);
      socket.on('v2-reconnect-answer', handleReconnectAnswer);
      socket.on("end-stream", endCall);
    }

    // Clean up function
    return () => {
      if (socket) {
        socket.off('v2-offer');
        socket.off('v2-answer');
        socket.off('v2-ice-candidate');
        socket.off('v2-reconnect-offer');
        socket.off('v2-reconnect-answer');
        socket.off('end-stream');
      }
    };
  }, [stream, socket]);

  useEffect(() => {
    // Clean up function
    return () => {
      endCall();
    };
  }, []);

  useEffect(() => {
    if (stream) {
      localVideoRef.current.srcObject = stream;
    }
    if (stream && isCaller) {
      startCall();
    }
  }, [stream]);

  const sendSignalingMessage = useCallback((message) => {
    socket.emit(message.type, callId, message);
  }, []);

  const createPeerConnection = useCallback(() => {
    peerConnectionRef.current = new RTCPeerConnection(servers);

    peerConnectionRef.current.ontrack = (event) => {
      console.log('PEER CONNECTION ON TRACK', event)
      setRemoteStream(event.streams[0]);
    };

    peerConnectionRef.current.onicecandidate = (event) => {
      if (event.candidate) {
        sendSignalingMessage({ type: 'v2-ice-candidate', candidate: event.candidate });
      }
    };

    peerConnectionRef.current.oniceconnectionstatechange = () => {
      console.log("ICE connection state:", peerConnectionRef.current.iceConnectionState);
      if (peerConnectionRef.current.iceConnectionState === 'disconnected' ||
        peerConnectionRef.current.iceConnectionState === 'failed') {
        attemptReconnection();
      }
    };

    peerConnectionRef.current.onconnectionstatechange = () => {
      console.log("Connection state:", peerConnectionRef.current.connectionState);
      if (peerConnectionRef.current.connectionState === 'disconnected' ||
        peerConnectionRef.current.connectionState === 'failed') {
        attemptReconnection();
      }
    };

    stream.getTracks().forEach((track) => {
      console.log('ADDING TRACKS');
      peerConnectionRef.current.addTrack(track, stream);
    });
  }, [stream, sendSignalingMessage]);

  const startCall = async () => {
    try {
      createPeerConnection();
      const offer = await peerConnectionRef.current.createOffer();
      await peerConnectionRef.current.setLocalDescription(offer);

      sendSignalingMessage({ type: 'v2-offer', offer: offer });
      setIsCallStarted(true);
    } catch (error) {
      console.error('Error starting call:', error);
    }
  };

  const handleOffer = async (offer) => {
    console.log('handleOffer')
    try {
      if (!peerConnectionRef.current) {
        createPeerConnection();
      }
      await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(offer));

      const answer = await peerConnectionRef.current.createAnswer();
      await peerConnectionRef.current.setLocalDescription(answer);
      console.log('sending answer', answer)
      sendSignalingMessage({ type: 'v2-answer', answer: answer });
      setIsCallStarted(true);
    } catch (error) {
      console.error('Error handling offer:', error);
    }
  };

  const handleAnswer = async (answer) => {
    try {
      console.log('handleAnswer')
      await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(answer));
    } catch (error) {
      console.error('Error handling answer:', error);
    }
  };

  const handleICECandidate = (candidate) => {
    try {
      console.log('handleICECandidate')
      peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(candidate));
    } catch (error) {
      console.error('Error handling ICE candidate:', error);
    }
  };

  const attemptReconnection = async () => {
    if (isReconnecting) return;
    setIsReconnecting(true);

    console.log("Attempting to reconnect...");

    try {
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
      }

      createPeerConnection();

      const offer = await peerConnectionRef.current.createOffer();
      await peerConnectionRef.current.setLocalDescription(offer);
      sendSignalingMessage({ type: 'v2-reconnect-offer', offer: offer });
    } catch (error) {
      console.error('Error attempting reconnection:', error);
    } finally {
      setIsReconnecting(false);
    }
  };

  const handleReconnectOffer = async (offer) => {
    try {
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
      }

      createPeerConnection();

      await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(offer));

      const answer = await peerConnectionRef.current.createAnswer();
      await peerConnectionRef.current.setLocalDescription(answer);

      sendSignalingMessage({ type: 'v2-reconnect-answer', answer: answer });
    } catch (error) {
      console.error('Error handling reconnect offer:', error);
    }
  };

  const handleReconnectAnswer = async (answer) => {
    try {
      await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(answer));
      console.log("Reconnection successful");
    } catch (error) {
      console.error('Error handling reconnect answer:', error);
    }
  };

  const hangUp = () => {
    if (consultation) {
      socket.emit("end-stream", { id: callId, consultation: consultation._id });
    }
    endCall();
  }

  const endCall = () => {
    if (peerConnectionRef.current) {
      peerConnectionRef.current.close();
      peerConnectionRef.current = null;
    }
    stopStream()
    if (remoteStream) {
      remoteStream.getTracks().forEach(track => track.stop());
      setRemoteStream(null);
    }
    setIsCallStarted(false);
    onEndStream();
  };

  useEffect(() => {
    if (remoteStream) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  return (
    <div className={styles.videoArea}>
      <div className={`${styles.videoScreen} d-flex mb-10`}>
        <video ref={localVideoRef} autoPlay={true} className={`${styles.localVideoScreen}`} id="local-video" playsInline muted></video>
        <video ref={remoteVideoRef} autoPlay={true} className={styles.remoteVideoScreen} id="remote-video" playsInline></video>
        <div className={styles.videoIcons}>
          <div className={`${styles.videoIcon} ${!hasAudio ? styles.hangup : ''}`} onClick={() => setAudio(!hasAudio)}>
            {hasAudio ? <Mic stroke="white" /> : <MicOff stroke="white" />}
          </div>
          <div className={`${styles.videoIcon} ${!hasVideo ? styles.hangup : ''}`} onClick={() => setVideo(!hasVideo)}>
            {hasVideo ? <Video stroke="white" /> : <VideoOff stroke="white" />}
          </div>
          <div className={`${styles.videoIcon} ${styles.hangup}`} onClick={hangUp}>
            <Phone stroke="white" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoCall;
