import {
    ADD_PATIENT,
    REMOVE_PATIENT,
} from '../types/index';

//adding a patient
export function add_patient(patients){
    return function(dispatch){
        try {
            dispatch({
                type:ADD_PATIENT,
                payload:patients
            });
        } catch (error) {
          console.log(error)  
        };
    }; 
}
//remove a patient
export function remove_patient(patients){
    return function(dispatch){
        try {
           dispatch({
            type:REMOVE_PATIENT,
            payload:patients
           }) 
        } catch (error) {
            console.log(error)
        }
    }
}