import React, { useState, useRef, useEffect } from 'react'
import logoIMG from "../media/logo_secondary.svg";
import logoIMGBlue from "../media/logo_primary.svg";
import check from '../media/checkJS.svg';
import errorIcon from '../media/error.svg';
import Alert from './utils/Alert';
import { useDispatch, useSelector } from 'react-redux';
import styles from './css/NewPassword.module.css';
import { useNavigate, Link } from 'react-router-dom';
import { setCiUser, nullError, setPasswordRedux } from '../actions/usersActions';

const NewPassword = ({ type }) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const usersReducer = useSelector(state => state.user);
    const { loading, error, user, msg, isLogged } = usersReducer;
    const inputOne = useRef('');
    const inputTwo = useRef('');
    const { id } = user;
    const [passwordUsers, setPassword] = useState({
        password: '',
        confirmPassword: ''
    });
    const { password, confirmPassword } = passwordUsers;

    const [validations, setValidations] = useState({
        minCharacters: false,
        atLeastOneLetter: false,
        atLeastOneNumber: false,
    });

    const [status, setStatus] = useState({
        border: '',
        msg: ''
    });

    const handleChange = () => {
        setPassword(prev => ({
            ...prev,
            password: inputOne.current.value,
            confirmPassword: inputTwo.current.value
        }));
    };

    useEffect(() => {
        setValidations(prev => ({
            ...prev,
            minCharacters: password.length > 5,
            atLeastOneNumber: /\d/.test(password),
            atLeastOneLetter: /[a-zA-Z]/.test(password),
            noEmojis: /^[A-Za-z0-9]*$/.test(password),
            areEquals: password === confirmPassword
        }))
    }, [password, confirmPassword]);

    useEffect(() => {
        if (isLogged) navigate('/dashboard');
    }, [isLogged])

    useEffect(() => {
        if (Object.values(validations).every(v => v)) {
            setStatus({ border: "border border-success", msg: 'Las contraseñas son válidas' })
        } else {
            if (validations.minCharacters) {
                if (!validations.atLeastOneLetter) {
                    setStatus({ border: "border border-danger", msg: 'Debe tener al menos una letra' })
                } else if (!validations.atLeastOneNumber) {
                    setStatus({ border: "border border-danger", msg: 'Debe tener al menos un número' })
                } else if (!validations.noEmojis) {
                    setStatus({ border: "border border-danger", msg: 'No se aceptan caracteres especiales' })
                } else if (!validations.areEquals) {
                    setStatus({ border: "border border-danger", msg: 'Las contraseñas no coinciden' })
                } else {
                    setStatus({ border: '', msg: '' })
                }
            } else {
                setStatus({ border: '', msg: '' })
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validations]);

    const handleSubmit = e => {
        e.preventDefault();
        //dispatch actions
        dispatch(setPasswordRedux({ type: type || 'doctor', id: user.id, password: password }));
        //clear inputs and actions
        setStatus('');
        setPassword({
            password: '',
            confirmPassword: ''
        })
        dispatch(nullError());
    }

    return (
        <section className='bg-primary'>
            <div className={`${type === 'admin' ? styles.adminPattern : type === 'insurance' ? styles.insurancePattern : styles.backPattern}`}>
                <div id="layoutAuthentication">
                    <div id="layoutAuthentication_content">
                        <main>
                            <div className="container-xl px-4">
                                <div className="column justify-content-center">
									<img src={type === 'insurance' ? logoIMGBlue : logoIMG} alt="logo" className='mt-5 mb-5 d-flex mx-auto col-md-4' />
                                    <div className="col-lg-6 mx-auto">
                                        {/* <!-- Basic login form--> */}
                                        <div className="card shadow-lg border-0 rounded-lg mt-3">
                                            <div className="card-header justify-content-center"><h3 className={"fw-normal my-4 " + styles.headerCustom}>Nueva contraseña</h3></div>
                                            <div className="card-body">
                                                <p className={styles.pcustom}>Cree una contraseña con la que podrá ingresar al sistema</p>
                                                <hr />
                                                {/* <!-- Login form--> */}
                                                <form onSubmit={handleSubmit}>
                                                    {/* <!-- Form Group (email address)--> */}
                                                    {
                                                        status.border !== '' ?
                                                            <Alert variant={status.border === "border border-danger" ? 'danger' : 'success'} msg={status.msg} />
                                                            :
                                                            null
                                                    }
                                                    <div className="mb-3">
                                                        <label className="small mb-1" htmlFor="ci">
                                                            Nueva Contraseña
                                                        </label>
                                                        <div className={"form-control align-middle " + status.border + ' ' + styles.tooltip}>
                                                            <span className={styles.tooltiptext}>
                                                                - Debe tener mínimo 6 caracteres <br />
                                                                - Al menos una letra <br />
                                                                - Al menos un número
                                                            </span>
                                                            <div className='h-75 d-flex' >
                                                                <input
                                                                    type="password"
                                                                    value={password}
                                                                    name='password'
                                                                    autoComplete='off'
                                                                    onChange={handleChange}
                                                                    ref={inputOne}
                                                                    maxLength={10}
                                                                    className={'col-11 border-0 ' + styles.inputOut}
                                                                />
                                                                {status.border === "border border-danger" ? <img src={errorIcon} className='align-baseline' alt='error' /> : null}
                                                                {status.border === "border border-success" ? <img src={check} className='align-baseline' alt='check' /> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* confirm password */}
                                                    <div className="mb-3">
                                                        <label className="small mb-1" htmlFor="ci">
                                                            Confirmación de contraseña
                                                        </label>
                                                        <div className={"form-control align-middle " + status.border + ' ' + styles.tooltip}>
                                                            <span className={styles.tooltiptext}>
                                                                - Debe tener mínimo 6 caracteres <br />
                                                                - Al menos una letra <br />
                                                                - Al menos un número
                                                            </span>
                                                            <div className={'h-75 d-flex '} >
                                                                <input
                                                                    type="password"
                                                                    value={confirmPassword}
                                                                    name='confirmPassword'
                                                                    autoComplete='off'
                                                                    onChange={handleChange}
                                                                    ref={inputTwo}
                                                                    maxLength={10}
                                                                    className={'col-11 border-0 ' + styles.inputOut}
                                                                />
                                                                {status.border === "border border-danger" ? <img src={errorIcon} className='align-baseline' alt='error' /> : null}
                                                                {status.border === "border border-success" ? <img src={check} className='align-baseline' alt='check' /> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    {/* <!-- Form Group (login box)--> */}
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <Link className={styles.linkTo} to="/logDr">
                                                            Regresar a mis datos
                                                        </Link>
                                                        <button
                                                            className="btn btn-primary btn-block"
                                                            type='submit'
                                                            disabled={status.border === "border border-success" ? false : true}
                                                        >
                                                            Guardar e ingresar
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NewPassword;