import React, { useState, useRef, useEffect } from 'react'
// css
import styles from './css/Login.module.css';
import logoIMG from "../media/logo_secondary.svg";
import logoIMGBlue from "../media/logo_primary.svg";
import Spinner from './utils/Spinner';
import check from '../media/checkJS.svg';
import errorIcon from '../media/error.svg';
// redux and react router dom
import { useNavigate } from 'react-router-dom';
import { formatNumberThousands } from '../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setCiUser, nullError, authLogin, loginPass } from '../actions/usersActions';
import Alert from './utils/Alert';
import Tag from './utils/Tag';

const Login = ({ type }) => {
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const usersReducer = useSelector(state => state.user);
	const { loading, error, user, msg, isLogged } = usersReducer;
	const inputRef = useRef('');
	const [ci, setCi] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [status, setStatus] = useState('');
	const [disabledBtn, setDisabledBtn] = useState(true);
	const { id } = user;

	useEffect(() => {
		if (isLogged) navigate("/dashboard")
	}, [])

	useEffect(() => {
		if (error === false && user.id) {
			let navigateTo = '/logDr';
			switch (type) {
				case 'admin':
					navigateTo = '/dashboard';
					break;
				case 'insurance':
					navigateTo = user.isAuth ? '/insurance/login' : '/insurance/auth/pass';
					break;
			}
			setStatus("border border-success")
			setTimeout(() => {
				setStatus('');
				dispatch(nullError());
				navigate(navigateTo);
			}, 1000)
		} else if (error === true) {
			setStatus("border border-danger")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, id]);

	useEffect(() => {
		if (type === 'admin') {
			setDisabledBtn(!(password && email));
		}
		if (type === 'insurance') {
			setDisabledBtn(!email);
		}
	}, [password, email])

	const handleChange = () => {
		//Validations
		if (formatNumberThousands(inputRef.current.value).length > 7 && status !== "border border-danger" && /^[0-9.]*$/.test(inputRef.current.value)) {
			setDisabledBtn(false);
		} else {
			setDisabledBtn(true);
		}
		if (!/^[0-9.]*$/.test(inputRef.current.value)) {
			setStatus("border border-danger");
		} else {
			setStatus('');
		};

		setCi(formatNumberThousands(inputRef.current.value));
	}
	const handleSubmit = async e => {
		e.preventDefault();
		let valueDispatched = Number(ci.split('').filter(e => e !== '.').join(''));
		switch (type) {
			case 'admin':
				dispatch(loginPass({ email, password, type }));
				break;
			case 'insurance':
				dispatch(authLogin({ email, type }));
				break;
			default:
				dispatch(setCiUser(valueDispatched));
		}
	};

	return (
		<section className='bg-primary'>
			<div className={`${type === 'admin' ? styles.adminPattern : type === 'insurance' ? styles.insurancePattern : styles.backPattern}`}>
				<div id="layoutAuthentication">
					<div id="layoutAuthentication_content">
						<main>
							<div className="container-xl px-4">
								<div className="column justify-content-center">
									<div className='mt-5 mb-5 d-flex mx-auto col-md-4 d-flex justify-content-center align-items-center'>
										<img src={type === 'insurance' ? logoIMGBlue : logoIMG} alt="logo" />
										{type === 'admin' && <div className={styles.adminTag}>ADMIN</div>}
									</div>
									<div className="col-lg-6 mx-auto">
										{/* <!-- Basic login form--> */}
										<div className="card shadow-lg border-0 rounded-lg mt-3">
											<div className="card-header justify-content-center"><h3 className="fw-normal my-4">Inicio de sesión</h3></div>
											<div className="card-body">
												{/* <!-- Login form--> */}
												<form onSubmit={handleSubmit}>
													{/* <!-- Form Group (email address)--> */}
													{
														error !== null ?
															<Alert variant={error ? 'danger' : 'success'} msg={msg} />
															:
															null
													}
													<div className="mb-3">
														{!type && <>
															<label className="small mb-1" htmlFor="ci">
																Cédula de identidad
															</label>
															<div className={"form-control align-middle " + status}>
																<div className='h-75 d-flex' >
																	<input
																		value={ci}
																		name='ci'
																		type="text"
																		ref={inputRef}
																		maxLength={10}
																		className={'col-11 fs-4 border-0 ' + styles.inputOut}
																		autoComplete='off'
																		onChange={handleChange}
																		placeholder="12.345.678" />
																	{status === "border border-danger" ? <img className='align-baseline' src={errorIcon} alt='error' /> : null}
																	{status === "border border-success" ? <img className='align-baseline' src={check} alt='check' /> : null}

																</div>
															</div>
														</>}
														{type === 'admin' && <>
															<label className="small mb-1" htmlFor="email">
																Correo electrónico
															</label>
															<div className={"form-control align-middle " + status}>
																<div className='h-75 d-flex' >
																	<input
																		value={email}
																		name='email'
																		type="email"
																		className={'col-11 fs-4 border-0 ' + styles.inputOut}
																		autoComplete='off'
																		onChange={(value) => { setEmail(value.target.value) }}
																		placeholder="" />
																	{status === "border border-danger" ? <img className='align-baseline' src={errorIcon} alt='error' /> : null}
																	{status === "border border-success" ? <img className='align-baseline' src={check} alt='check' /> : null}

																</div>
															</div>
															<label className="small mb-1 mt-3" htmlFor="password">
																Contraseña
															</label>
															<div className={"form-control align-middle " + status}>
																<div className='h-75 d-flex' >
																	<input
																		value={password}
																		name='password'
																		type="password"
																		className={'col-11 fs-4 border-0 ' + styles.inputOut}
																		autoComplete='off'
																		onChange={(value) => setPassword(value.target.value)} />
																	{status === "border border-danger" ? <img className='align-baseline' src={errorIcon} alt='error' /> : null}
																	{status === "border border-success" ? <img className='align-baseline' src={check} alt='check' /> : null}
																</div>
															</div>
														</>}
														{type === 'insurance' && <>
															<label className="small mb-1" htmlFor="email">
																Correo electrónico
															</label>
															<div className={"form-control align-middle " + status}>
																<div className='h-75 d-flex' >
																	<input
																		value={email}
																		name='email'
																		type="email"
																		className={'col-11 fs-4 border-0 ' + styles.inputOut}
																		autoComplete='off'
																		onChange={(value) => setEmail(value.target.value)} />
																	{status === "border border-danger" ? <img className='align-baseline' src={errorIcon} alt='error' /> : null}
																	{status === "border border-success" ? <img className='align-baseline' src={check} alt='check' /> : null}
																</div>
															</div>
														</>}
													</div>
													<hr />
													{/* <!-- Form Group (login box)--> */}
													<div className="d-flex align-items-center justify-content-between mt-4 mb-0">
														<button
															type='submit'
															disabled={disabledBtn}
															className={"btn btn-primary p-3 col-3 " + styles.btnColor} to="8.8.8.8">
															{loading ? <Spinner /> : type === 'admin' ? 'Iniciar sesión' : 'Continuar'}
														</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</main>
					</div>
				</div>
			</div>
			<div className={styles.floatingHelpers}>
				<a href="/">
					<Tag title="Doctores">
					</Tag>
				</a>
				<a href="/insurance">
					<Tag title="Aseguradoras">
					</Tag>
				</a>
				<a href="/admin">
					<Tag title="Administrador">
					</Tag>
				</a>
			</div>
		</section>
	);
}

export default Login;