import React, { useState } from 'react';
import { useEffect } from 'react';
import { getHours } from '../../helpers';
import './css/Turns.css'

export const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export const DAYS_SUN_ZERO = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const DAYS_DICT = {
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  sunday: 'Domingo'
}
export const DAYS_OPTIONS = [
  { key: 'monday', name: 'Lunes' },
  { key: 'tuesday', name: 'Martes' },
  { key: 'wednesday', name: 'Miércoles' },
  { key: 'thursday', name: 'Jueves' },
  { key: 'friday', name: 'Viernes' },
  { key: 'saturday', name: 'Sábado' },
  { key: 'sunday', name: 'Domingo' }
]

const Turn = ({ day, turn }) => {
  return <div className='turn'>
    <h6>{day}</h6>
    <div className='turn-progress'>
      <span className='lilball'></span>
    </div>
    <div>
      {turn.map(({ type, startHour, endHour, startMinute, endMinute }, i) => {
        return <div key={i}>
        {type === 'break' && <p className="small mb-1 text-primary fw-600">Break</p>}
        <div className='d-flex flex-grow-1 turn-time'>
          <div>{getHours(startHour, startMinute)}</div>
          <div className='separator' />
          <div>{getHours(endHour, endMinute)}</div>
        </div>
        </div>
      })}
    </div>
  </div>
}

const Turns = ({ data, days }) => {
  const [turns, setTurns] = useState([]);

  useEffect(() => {
    const renderedTurns = [];
    for (const day of days || DAYS) {
      if (data[day]) renderedTurns.push(<Turn key={day} day={DAYS_DICT[day]} turn={data[day]} />);
    }
    setTurns(renderedTurns);
  }, [data])

  return (
    <div className='turn-container'>
      {turns}
    </div>
  );
}

export default Turns;