import React, { useState, useEffect } from 'react'
// css
import styles from '../css/patients.module.css';
import Spinner from './../utils/Spinner';
// redux and react router dom
import { useNavigate } from 'react-router-dom';
import { formatNumberThousands } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setCiUser, nullError, authLogin, loginPass, registerNewUserByCi } from '../../actions/usersActions';
import LoginContainer from './LoginContainer';
import CustomInput from '../utils/Input';
import Button from '../utils/Button';
import ErrorMessage from './components/ErrorMessage';

const LoginPatients = ({ type }) => {
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const { loading, user, msg } = useSelector(state => state.user);;
	const [ci, setCi] = useState('');
	const [disabledBtn, setDisabledBtn] = useState(true);

	useEffect(() => {
		if (user.id) navigate("/patients/login-register")
	}, [user])

	useEffect(() => {
		setDisabledBtn(!ci);
	}, [ci])

	const validations = [
		(data) => /^[0-9.]*$/.test(data)
	]

	const handleSubmit = async e => {
		e.preventDefault();
		const [nationality, ced] = ci.split('-');
		dispatch(registerNewUserByCi({ ci: Number(ced), nationality }));
	};

	return (
		<section>
			<div id="layoutAuthentication">
				<div id="layoutAuthentication_content">
					<main>
						<LoginContainer title="Buenas tardes," subtitle={"Gracias por confiar en nosotros, \npor favor ingrese su cédula de identidad."}>
							{/* <!-- Basic login form--> */}
							{/* <!-- Login form--> */}
							<div className="offset-1 col-10">
								<form onSubmit={handleSubmit}>
									<div className="my-4">
										<CustomInput
											type="ci" placeholder="12.345.678"
											validations={validations}
											label="Cédula de identidad"
											data={ci}
											setData={(val) => {
												setCi(val);
											}}></CustomInput>
									</div>
									<ErrorMessage message={msg}/>
									<Button type='submit' className='mt-4 mb-0 w-100' loading={loading} disabled={disabledBtn} text={"Ingresar"}></Button>
								</form>
							</div>
						</LoginContainer>
					</main>
				</div>
			</div >
		</section >
	);
}

export default LoginPatients;