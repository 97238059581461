import styles from './css/Login.module.css'
import logoIMG from '../media/logo_secondary.svg'
import logoIMGBlue from "../media/logo_primary.svg";
import React, { useState, useRef, useEffect } from 'react';
import Alert from './utils/Alert';
import check from '../media/checkJS.svg';
import errorIcon from '../media/error.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginPass, loginWithPassword, nullError } from '../actions/usersActions';
import { useNavigate } from 'react-router-dom';


const LoginPass = ({ type }) => {

	// redux and redux state
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const usersReducer = useSelector(state => state.user);
	const [navigateTo, setNavigateTo] = useState('/dashboard');
	const { error, user, msg, isLogged } = usersReducer;

	useEffect(() => {
		setNavigateTo('/dashboard')
	}, [type])


	//hooks
	const inputRef = useRef('');
	const [status, setStatus] = useState('');
	const [password, setPassword] = useState('')

	useEffect(() => {
		if (isLogged) navigate(navigateTo)
	}, [isLogged])

	//effects
	useEffect(() => {
		if (error) {
			setStatus("border border-danger");
		} else if (error === false && msg === "Autenticación exitosa") {
			setStatus("border border-success")
			setTimeout(() => {
				setStatus('');
				dispatch(nullError());
				setStatus("");
				navigate(navigateTo)

			}, 1000)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error])

	//handling the password
	const handleChange = () => {
		setPassword(inputRef.current.value);
	};

	//submit the password
	const handleSubmit = (e) => {
		e.preventDefault();
		//validations
		if (password.trim() === "") {
			return setStatus("border border-danger")
		}
		//dispatch action
		dispatch(type === 'insurance' ? loginPass({ type, password, id: user.id }) : loginWithPassword({ password, id: user.id }));
		//clear form
		setPassword("");
	};

	return (
		<section className='bg-primary'>
			<div className={`${type === 'admin' ? styles.adminPattern : type === 'insurance' ? styles.insurancePattern : styles.backPattern}`}>
				<div id="layoutAuthentication">
					<div id="layoutAuthentication_content">
						<main>
							<div className="container-xl px-4">
								<div className="column justify-content-center">
									<img src={type === 'insurance' ? logoIMGBlue : logoIMG} alt="logo" className='mt-5 mb-5 d-flex mx-auto col-md-4' />
									<div className="col-lg-6 mx-auto">
										{/* <!-- Basic login form--> */}
										<div className="card shadow-lg border-0 rounded-lg mt-3">
											<div className="card-header justify-content-center"><h3 className="fw-normal my-4"> { type === 'insurance' ? 'Inicio de sesión' : user.gender === 'M' ? `Bienvenido Dr. ${user.lastName}` : `Bienvenida Dra. ${user.lastName}`}</h3></div>
											<div className="card-body">
												{/* <!-- Login form--> */}
												<form onSubmit={handleSubmit}>
													{/* <!-- Form Group (email address)--> */}
													{
														error !== null ?
															<Alert variant={error ? 'danger' : 'success'} msg={msg} />
															:
															null
													}
													<div className="mb-3">
														<label className="small mb-1" htmlFor="ci">
															Contraseña
														</label>
														<div className={"form-control align-middle " + status}>
															<div className='h-75 d-flex' >
																<input
																	value={password}
																	name='ci'
																	type="password"
																	ref={inputRef}
																	maxLength={18}
																	className={'col-11 fs-4 border-0 ' + styles.inputOut}
																	autoComplete='off'
																	onChange={handleChange}
																/>
																{status === "border border-danger" ? <img className='align-baseline' src={errorIcon} alt='error' /> : null}
																{status === "border border-success" ? <img className='align-baseline' src={check} alt='check' /> : null}

															</div>
														</div>
													</div>
													<hr />
													{/* <!-- Form Group (login box)--> */}
													<div className='d-flex justify-content-between align-items-center'>
														<Link className={styles.linkTo} to="/" onClick={() => dispatch(nullError())}>
															Regresar al inicio
														</Link>
														<button
															className="btn btn-primary btn-block"
															disabled={!password.length}
															to="/"
														>
															Ingresar
														</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</main>
					</div>
				</div>
			</div>
		</section>

	);
}

export default LoginPass;