import React from 'react';
import './css/Tag.css'

const Tag = ({ title, className, inline = false }) => {
  return (
    <div className={`tag${inline ? 'inline' : ''} ${className}`}>
      <span className='fw-800'>{title}</span>
    </div>
  );
}

export default Tag;