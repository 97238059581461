import React, { useEffect, useMemo, useState } from 'react'
import svg1 from "../assets/img/illustrations/statistics.svg";
import { Info, TrendingDown, TrendingUp, Calendar } from 'react-feather';
//datepicker
import "react-datepicker/dist/react-datepicker.css";
import styles from './css/Dash.module.css'
//redux
import { useDispatch, useSelector } from 'react-redux';
//helpers
import { evalSchedule, formatNumberThousands, evalHours, getConsultationTime } from '../helpers';
//navigations
import ModalStopTurn from './utils/modals/ModalStopTurn';
import useSocket from '../hooks/useSocket';
import { getMetrics, resetCurrentPatient, setCurrentPatient, setDoctorTurnActive } from '../actions/usersActions';
import PageTitle from './utils/PageTitle';
import { add_patient } from '../actions/queueActions';
import ModalFinishTurn from './utils/modals/ModalFinishTurn';
import ModalConsultationFinished from './utils/modals/ModalConsultationFinished';
import ModalWait from './utils/modals/ModalWait';
import { setCurrentDoctor } from '../actions/patientActions';

const DoctorDashboard = () => {
	const [socket, socketId] = useSocket();
	const dispatch = useDispatch();
	const { patients, doctor } = useSelector(state => state.queue);
	const [turnDuration, setTurnDuration] = useState(0);
	const { turnActive, user, lastPatient, finishedConsultation, metrics } = useSelector(state => state.user);
	const [ready, setReady] = useState(false);

	useEffect(() => {
		dispatch(resetCurrentPatient());
		if (user.type === 'doctor' && socket) {
			//update queue
			socket.on('colaActualizada', (data) => {
				console.log(200, data)
				const doctor = data.doctors.find(doc => doc.data.id === user.id);
				dispatch(setCurrentDoctor(doctor))
				dispatch(add_patient(data.patients))
			})
			socket.on('goToMeetDr', id => {
				if (id) {
					setReady(true);
				};
				return () => {
					socket.off('goToMeerDr');
				};
			});
			socket.on('cancelMeetDr', id => {
				setShowModal({
					modalFinish: false,
					modalWait: false,
					modalPause: false
				})
				return () => {
					socket.off('cancelMeetDr');
				};
			});
			dispatch(getMetrics(user.id));
		}
		//cleanup all side effects
		return () => {
			if (socket) {
				socket.off('colaActualizada');
				socket.off('goToMeerDr');
			}
		}

	}, [socket]);

	const setTurnActive = (active) => {
		dispatch(setDoctorTurnActive(active));
	}

	const initTurn = () => {
		setTurnActive(true);
		socket.emit('usuarioConectado', {
			id: user.id,
			photo: user.photo,
			name: user.name,
			lastName: user.lastName,
			gender: user.gender,
			cm: user.cm,
			mpps: user.mpps
		});
	}

	const pauseTurn = () => {
		getDoctorTime();
		setShowModal({
			modalFinish: false,
			modalWait: false,
			modalPause: true
		});
		socket.emit('pauseTurn');
	}

	const endTurn = () => {
		getDoctorTime();
		setShowModal({
			modalFinish: true,
			modalWait: false,
			modalPause: false
		});
	}

	//Queue
	const arrClass = [
		styles.child0,
		styles.child1,
		styles.child2,
		styles.child3,
		styles.child4,
		styles.child5
	];
	const renderQueue = (arr) => {
		const result = [];
		const maxValue = arr.length <= 5 ? arr.length : 5;
		for (let i = 0; i < maxValue; i++) {
			result.push(<div key={i} className={`${styles.child} ${arrClass[i]}`}>{arr[i].patient}</div>)
		}
		if (arr.length >= 6) {
			const num = arr.length > 14 ? 9 : arr.length - 5;
			result.push(<div key={6} className={`${styles.child} ${arrClass[5]}`}>+{num}</div>);
		}
		return result
	}
	//datePicker 
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;

	//modals render
	const [showModal, setShowModal] = useState({
		modalFinish: false,
		modalWait: false,
		modalConsultationFinished: finishedConsultation,
		modalPause: false
	})

	//if the doctor click on start meet 
	const startMeet = (patient) => {
		//send a advise for indicate that the doctor is ready and he gets the first patient in the queue
		socket.emit('startMeet', patient);
		dispatch(setCurrentPatient(patient));
		setShowModal({
			modalFinish: false,
			modalWait: true,
			modalPause: false
		})
		//go to meet screen and disconnect socket manually
	}

	const getDoctorTime = () => {
		if (doctor) {
			const duration = doctor.duration + Date.now() - doctor.start;
			setTurnDuration(getConsultationTime(duration / 1000));
		}
	}

	return (
		<>
			{
				showModal.modalFinish &&
				<ModalFinishTurn
					showModal={showModal}
					setShowModal={setShowModal}
					setTurnActive={setTurnActive}
					title='Terminar turno'
					body={turnDuration}
				/>
			}
			{
				showModal.modalConsultationFinished &&
				<ModalConsultationFinished
					showModal={showModal}
					setShowModal={setShowModal}
					title='Consulta exitosa'
					body={lastPatient.name + ' ' + lastPatient.lastName}
				/>
			}
			{
				showModal.modalWait &&
				<ModalWait
					showModal={showModal}
					setShowModal={setShowModal}
					ready={ready}
					title='Esperando confirmación del paciente'
					body='El paciente está confirmando su invitación, por favor no cierre esta ventana mientras tanto'
				/>
			}
			{/* <!-- Custom page header alternative example--> */}
			<PageTitle title="Consultas">
				{/*
				<div className={styles.containerPicker}>
					<Calendar />
					<DatePicker
						selectsRange={true}
						startDate={startDate}
						endDate={endDate}
						onChange={(update) => {
							setDateRange(update);
						}}
						withPortal
						className={styles.picker}
						placeholderText='Búsqueda por fecha'
					/>
				</div>*/}
			</PageTitle>
			{/* <!-- Illustration DoctorDashboard card example--> */}
			<div className="row">
				<div className="col-xl-4 col-md-6 mb-4">
					{/* <!-- DoctorDashboard info widget 1--> */}
					<div className="card border-start-lg border-start-primary h-100">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1">
									<div className="small fw-bold text-primary mb-1">Pacientes atendidos (mes)</div>
									<div className="h5">{metrics.currentMonth.totalPatients} paciente{metrics.currentMonth.totalPatients !== 1 ? 's' : ''}</div>
									<div className={`${metrics.patientTrendDirection === 'up' ? 'text-success' : ''} ${metrics.patientTrendDirection === 'down' ? 'text-danger' : ''} text-xs fw-bold d-inline-flex align-items-center`}>
										{metrics.patientTrendDirection === 'up' && <TrendingUp className="me-1" />}
										{metrics.patientTrendDirection === 'down' && <TrendingDown className="me-1" />}
										{metrics.patientTrend}%
									</div>
								</div>
								<div className="ms-2"><i className="fas fa-dollar-sign fa-2x text-gray-200"></i></div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-4 col-md-6 mb-4">
					{/* <!-- DoctorDashboard info widget 2--> */}
					<div className="card border-start-lg border-start-primary h-100">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1">
									<div className="small fw-bold text-primary mb-1">Cantidad de médicos (mes)</div>
									<div className="h5">{metrics.currentMonth.totalDoctors}</div>
									<div className={`${metrics.doctorsTrendDirection === 'up' ? 'text-success' : ''} ${metrics.doctorsTrendDirection === 'down' ? 'text-danger' : ''} text-xs fw-bold d-inline-flex align-items-center`}>
										{metrics.doctorsTrendDirection === 'up' && <TrendingUp className="me-1" />}
										{metrics.doctorsTrendDirection === 'down' && <TrendingDown className="me-1" />}
										{metrics.doctorsTrend}%
									</div>
								</div>
								<div className="ms-2"><i className="fas fa-tag fa-2x text-gray-200"></i></div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-4 col-md-6 mb-4">
					{/* <!-- DoctorDashboard info widget 3--> */}
					<div className="card border-start-lg border-start-primary h-100">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1">
									<div className="small fw-bold text-primary mb-1">Tiempo total en consulta (mes)</div>
									<div className="h5">{getConsultationTime(metrics.currentMonth.totalDuration)}</div>
									<div className={`${metrics.durationTrendDirection === 'up' ? 'text-success' : ''} ${metrics.durationTrendDirection === 'down' ? 'text-danger' : ''} text-xs fw-bold d-inline-flex align-items-center`}>
										{metrics.durationTrendDirection === 'up' && <TrendingUp className="me-1" />}
										{metrics.durationTrendDirection === 'down' && <TrendingDown className="me-1" />}
										{metrics.durationTrend}%
									</div>
								</div>
								<div className="ms-2"><i className="fas fa-mouse-pointer fa-2x text-gray-200"></i></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="card  mb-4 mt-5">
				{
					turnActive &&
					<div className={styles.headerTurn + ' d-flex flex-row py-1 justify-content-between'}>
						<h3 className='my-0 mx-2'>Turno Activo</h3>
						<div>
							<button className='btn btn-primary mx-1'
								onClick={pauseTurn}
							>
								Pausar turno
							</button>
							<button className='btn btn-outline-primary mx-1'
								onClick={endTurn}
							>Terminar mi turno</button>
						</div>
					</div>
				}
				<div className="card-body p-5">
					{/* modals */}
					{
						showModal.modalPause === true &&
						<ModalStopTurn
							className='col-4'
							showModal={showModal}
							setShowModal={setShowModal}
							title='Turno en pausa'
							body={`Tiempo transcurrido en turno actual: ${turnDuration}`}
						/>
					}
					<div className="row align-items-center justify-content-between">
						<div className="col">
							<h2 className="text-primary">Sala de espera</h2>
							<p className="text-gray-700">Actualmente hay {patients.length ? patients.length : '0'} paciente en espera por ser atendidos.</p>
							<div className={styles.contaierCirclePatient}>
								{patients.length ? renderQueue(patients) : <p className='text-gray-700'>No hay nadie en la cola aún</p>}
							</div>

							{
								turnActive ?
									//if not exists patients yet should be show that there aren't patients yet
									patients.length ?
										<div className={styles.turnContainer + ' d-flex flex-row align-items-center'}>
											{/* patient info */}
											<div>
												<div className={'d-flex md-flex-row my-3'}>
													{/* photo */}
													<div className={styles.imgPatient}>
														{patients.length && patients[0].patient}
													</div>
													{/* name */}
													<div className={'mx-3'}>
														<h3 className={'text-primary font-weight-bold'}>
															{patients.length && patients[0].name + ' ' + patients[0].lastName}
														</h3>
														<h5 className={'font-weight-600 text-dark'}>{patients.length && patients[0].nationality}-{patients.length && formatNumberThousands(patients[0].ci.toString())}</h5>
													</div>
												</div>

												<h5 className={'text-primary font-weight-600'}>Motivo de consulta</h5>
												<h4 className={'font-weight-bold text-dark'}>{patients.length && patients[0].consultation.subject}</h4>
											</div>
											{/* start meet */}
											<button
												onClick={() => startMeet(patients[0])}
												className="btn btn-primary p-3 col-md-2 h-25"
											>
												Iniciar consulta
											</button>
										</div>
										:
										<h4>No hay pacientes para atender todavía</h4>
									:
									<>
										<div className='d-flex  flex-column'>
											<button
												className="btn btn-primary p-3 col-md-4"
												disabled={evalHours(user.availability || {})}
												onClick={initTurn}
											>
												Iniciar mi turno
											</button>
											<br />
										</div>
									</>
							}
						</div>
						{
							!turnActive &&
							<div className="col d-none d-lg-block mt-xxl-n4 pt-5">
								<img className="img-fluid  mt-xxl-n5" src={svg1} alt='' />
							</div>
						}
					</div>


				</div>
			</div>

			{lastPatient.patient &&
				<div className="card  mb-4 mt-5" >
					<div className={styles.headerTurn + ' d-flex flex-row py-1 justify-content-between'}>
						<h3 className={styles.headerPrevious + ' text-primary bold my-0 mx-2'}>Paciente Anterior</h3>
					</div>
					<div className="card-body p-4">
						<div className="row align-items-center justify-content-between">
							<div className="col">
								<div className={' d-flex flex-row align-items-center'}>
									{/* patient info */}
									<div>
										<div className={'d-flex md-flex-row mb-3'}>
											{/* photo */}
											<div className={styles.imgPatient}>
												{lastPatient.patient}
											</div>
											{/* name */}
											<div className={'mx-3'}>
												<h3 className={'text-primary font-weight-bold'}>
													{lastPatient.name + ' ' + lastPatient.lastName}
												</h3>
												<h5 className={'font-weight-600 text-dark'}>{lastPatient.nationality}-{formatNumberThousands(lastPatient.ci.toString())}</h5>
											</div>
										</div>

										<h5 className={'text-primary font-weight-600'}>Motivo de consulta</h5>
										<h4 className={'font-weight-bold text-dark'}>{lastPatient.consultation.subject}</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</>

	);
}

export default DoctorDashboard;