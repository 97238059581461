import React, { useState, useEffect } from 'react'
// css
import styles from '../css/patients.module.css';
import Spinner from './../utils/Spinner';
// redux and react router dom
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoginContainer from './LoginContainer';
import CustomInput from '../utils/Input';
import Button from '../utils/Button';
import { ChevronLeft } from 'react-feather';
import { showInfoModal } from '../../actions/modalActions';
import { resetUsers } from '../../actions/usersActions';

const RecoverPassword = () => {
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const { loading } = useSelector(state => state.user);
	const [email, setEmail] = useState('');

	const goBack = () => {
		dispatch(resetUsers());
		navigate("/patients/login");
	}

	const handleSubmit = () => {
		dispatch(showInfoModal(
			'Correo enviado con éxito',
			`Por favor revise su correo electrónico, hemos enviado las instrucciones para reestablecer su contraseña.`,
			'Volver al inicio',
			'/patients/login',
		));
	};

	return (
		<section>
			<div id="layoutAuthentication">
				<div id="layoutAuthentication_content">
					<main>
						<LoginContainer size={684} title="Recuperar contraseña" subtitle={"Por favor introduzca el correo electrónico con el que está registrado."}>
							{/* <!-- Basic login form--> */}
							{/* <!-- Login form--> */}
							<form onSubmit={handleSubmit}>
								<CustomInput className="offset-2 col-8 my-5" type="email" label="Correo electrónico" data={email} setData={(val) => setEmail(val)}></CustomInput>
								<div className='d-flex justify-content-between align-items-center'>
									<Button onClick={goBack} className='mt-4 mb-0 ps-0' btn={"transparent"} text={<><ChevronLeft /><p className='ps-3'>Volver al inicio</p></>}></Button>
									<Button type="submit" className='mt-4 mb-0' loading={loading} disabled={!email} text={"Enviar link de recuperación"}></Button>
								</div>
							</form>
						</LoginContainer>
					</main>
				</div>
			</div >
		</section >
	);
}

export default RecoverPassword;