import React, { useEffect } from 'react';
import styles from './modalsCSS/Modal.module.css';
import stylesModal from './modalsCSS/ModalFinishTurn.module.css';
import { X } from 'react-feather'
import DoctorData from '../../patients/components/DoctorData';
import Rating from '../../patients/components/Rating';
import { getConsultationTime, getDateFull } from '../../../helpers';

function ModalConsultation({ setShowModal, consultation }) {
  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      setShowModal(false)
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown)
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown)
    }
  }, [])

  const closeModal = () => {
    setShowModal(false);
  }

  return (

    <div className={`${styles.modal} ${stylesModal.modalF}`} onClick={closeModal}>
      <div className={styles.consultationModalContent} onClick={e => e.stopPropagation()}>
        <div className={`${styles.modalConsultationHeader} patternBg`}>
          <DoctorData doctor={consultation.doctor} />
          <div className={styles.modalCloseX} onClick={closeModal}>
            <X color={'black'}/>
          </div>
        </div>

        <div style={{ paddingBlock: 40, paddingInline: 64 }} className={`row`}>
          <div className='col-12 mb-4'>
            <p>Motivo de la consulta</p>
            <p className='fw-800 primary-dark'>{consultation.subject}</p>
          </div>
          <div className='col-6'>
            <p>Fecha de la consulta</p>
            <p className='fw-800 primary-dark'>{getDateFull(consultation.startDate)}</p>
          </div>
          <div className='col-6'>
            <p>Duración de la consulta</p>
            <p className='fw-800 primary-dark'>{getConsultationTime((consultation.duration || (new Date(consultation.endDate) - new Date(consultation.startDate)))/ 1000)}</p>
          </div>
          <div className='col-12 mt-4'>
            <p>Comentarios</p>
            <p className='fw-800 primary-dark'>{consultation.comments || '-'}</p>
          </div>
        </div>
        <div style={{ paddingBottom: 48 }}>
          <Rating showText rating={consultation.rating}></Rating>
        </div>
      </div>
    </div>
  );
}

export default ModalConsultation;