import React from 'react';
import './css/TitleCard.css'

const TitleCard = ({ title, className, titleContent, children }) => {
  return (
    <div className={`card ${className}`}>
      <div className={'titleCard d-flex flex-row py-1 justify-content-between'}>
        { title 
          ? <h3 className={'headerPrevious text-primary bold my-0 mx-2'}>{title}</h3>
          : <>{titleContent}</>
        }
      </div>
      <div className="card-body p-4">
        {children}
      </div>
    </div>
  );
}

export default TitleCard;