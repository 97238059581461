import React, { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate, useParams } from 'react-router-dom';
import { createDoctor, getDoctorConsultation, getDoctors, setDoctorRegistration, updateDoctor, updateDoctorImage, uploadPhoto } from '../actions/usersActions';
import { formatNumberThousands, generateDatetime, getDate, formatPhoneNumber } from '../helpers';
import './css/Profile.css'
import DatePicker from 'react-datepicker';
import PageTitle from './utils/PageTitle';
import TitleCard from './utils/TitleCard';
import Turns, { DAYS_OPTIONS } from './utils/Turns';
import DoctorImage from './utils/DoctorImage';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from './utils/Input';
import TurnsInput from './utils/TurnsInput';
import ModalInfo from './utils/modals/ModalInfo'

const skillOptions = [{ key: 'Internista', name: 'Internista' }];
const genderOptions = [{ key: 'M', name: 'Masculino' }, { key: 'F', name: 'Femenino' }];

const RegisterEdit = () => {
	const navigate = useNavigate();
    const { id } = useParams();
    const imgInput = useRef();
    const dispatch = useDispatch();
    const { doctorRegistration = {} } = useSelector(state => state.user);
    const [showModal, setShowModal] = useState(false);
    const [page, setPage] = useState(0);
    const [days, setDays] = useState([]);

    useEffect(() => {
        getDoctor(id);
    }, [id])

    const getDoctor = async (id) => {
        const doc = await getDoctors(id);
        setDays(Object.keys(doc.data.data.availability || {}));
        dispatch(setDoctorRegistration({ ...doc.data.data }));
    }

    // Doctor state
    const setDoctorState = (key, value) => {
        doctorRegistration[key] = value;
        dispatch(setDoctorRegistration({ ...doctorRegistration }));
    }

    const RegisterTab = ({ title, index, page }) => {
        const active = index === page;
        return <div className={active ? 'regedit active' : 'regedit'}>
            <div>{index + 1}</div>
            <div>{title}</div>
        </div>
    }
    const pages = [
        { description: 'Selecciona una foto de perfil para el médico.', title: 'Foto de perfil' },
        { description: 'Ingresa los datos personales del médico y su información de contacto.', title: 'Datos Personales' },
        { description: 'Ingresa los datos profesionales del médico.', title: 'Datos profesionales' },
        { description: 'Define la programación de los turnos y breaks del médico.', title: 'Programación' },
    ];

    const onPhotoChange = async (e) => {
        const response = await uploadPhoto(e.target.files[0]);
        if (response.status === 200) dispatch(setDoctorRegistration({ ...doctorRegistration, photo: response.data.data }));
    }

    const handleSubmit = async (e) => {
        try {
            const payload = {
                ...doctorRegistration
            };
            // Parsing special characters
            payload.numberPhone = payload.numberPhone.toString().replace(/[\.() ]/g, '');
            payload.ci = payload.ci.toString().replace(/\./g, '');
            payload.mpps = payload.mpps.toString().replace(/\./g, '');
            payload.cm = payload.cm.toString().replace(/\./g, '');

            if (id) {
                await updateDoctor(id, payload);
            } else {
                await createDoctor(payload);
            }
            setShowModal(true);
        } catch(err) {
            console.log(err);
        }
    }

    const goToDoctors = () => {
		navigate("/dashboard/doctors")
    }

    return <>

        {showModal && <ModalInfo
            className='col-4'
            showModal={showModal}
            setShowModal={setShowModal}
            onClose={goToDoctors}
            title={`Médico ${id ? 'editado' : 'agregado'} exitosamente`}
            body={<>
                <p className='my-0 text-start'>Haz completado con éxito el registro {doctorRegistration.gender === 'F' ? 'de la' : 'del'} <strong className='text-dark'>{doctorRegistration.gender === 'F' ? 'Dra' : 'Dr'}. {doctorRegistration.name} {doctorRegistration.lastName}</strong>.</p><br />
                <p className='my-0 text-start'>Ahora {doctorRegistration.gender === 'F' ? 'la Dra' : 'el Dr'}. {doctorRegistration.name} estará disponible para realizar consultas virtuales en la programación asignada.</p>
            </>}
        />}
        <PageTitle title={`${id ? 'Editar médico' : 'Registrar nuevo médico'}`} />
        <TitleCard titleContent={<div className="p-3 d-flex justify-content-center flex-wrap">{pages.map(({ title }, i) => <RegisterTab key={`tabheader-${i}`} title={title} index={i} page={page} />)} </div>}>
            <div className='register-container'>
                <h4 className='fw-600 text-primary'>Paso {page + 1}</h4>
                <div className="fw-600 small text-gray-900">{pages[page].description}</div>
                {page === 0 && <div style={{ gap: 40 }} className='mt-5 mb-5 d-flex align-items-center'>
                    <DoctorImage size={160} src={doctorRegistration.photo} />
                    <div className='d-flex flex-column align-items-baseline'>
                        <div className="small text-gray-500 mt-4">JPG or PNG con tamaño máximo de 5 MB</div>
                        <input ref={imgInput} style={{ display: 'none' }} type="file" accept="image/*" onChange={onPhotoChange} />
                        <button className="btn btn-primary mt-4" onClick={() => imgInput.current.click()}>Cargar nueva foto</button>
                    </div>
                </div>}
                {page === 1 && <div className='mt-4 mb-4 row'>
                    <CustomInput className='mb-3 col-6' label="Nombres" data={doctorRegistration.name} setData={(val) => setDoctorState('name', val)}></CustomInput>
                    <CustomInput className='mb-3 col-6' label="Apellidos" data={doctorRegistration.lastName} setData={(val) => setDoctorState('lastName', val)}></CustomInput>
                    <CustomInput className='mb-3 col-6' label="Cédula de identidad" data={formatNumberThousands(doctorRegistration.ci)} setData={(val) => setDoctorState('ci', formatNumberThousands(val))}></CustomInput>
                    <CustomInput className='mb-3 col-6' type="date" label="Fecha de nacimiento" data={doctorRegistration.birthDate} setData={(val) => setDoctorState('birthDate', val)}></CustomInput>
                    <CustomInput className='mb-3 col-6' label="Sexo" type="pick" options={genderOptions} data={doctorRegistration.gender} setData={(val) => setDoctorState('gender', val)}></CustomInput>
                    <div className="w-100"></div>
                    <CustomInput className='mb-3 col-6' label="Correo electrónico" data={doctorRegistration.email} setData={(val) => setDoctorState('email', val)}></CustomInput>
                    <CustomInput config={{ mask: '+58 (999) 999 99 99'}} className='mb-3 col-6' label="Número de teléfono" data={doctorRegistration.numberPhone} setData={(val) => setDoctorState('numberPhone', val)}></CustomInput>
                </div>}
                {page === 2 && <div className='mt-4 mb-4 row'>
                    <CustomInput config={{ hideAll: true }} className="mb-3 col-6" type="select" options={skillOptions} placeholder="-- Selecciona una especialidad --" label="Especialidad" data={doctorRegistration.skill} setData={(val) => setDoctorState('skill', val)}></CustomInput>
                    <div className="w-100"></div>
                    <CustomInput config={{ maxLength: 7 }} className='mb-3 col-6' label="Colegio médico" data={formatNumberThousands(doctorRegistration.cm)} setData={(val) => setDoctorState('cm', formatNumberThousands(val))}></CustomInput>
                    <div className="w-100"></div>
                    <CustomInput config={{ maxLength: 7 }} className='mb-3 col-6' label="M.P.P.S" data={formatNumberThousands(doctorRegistration.mpps)} setData={(val) => setDoctorState('mpps', formatNumberThousands(val))}></CustomInput>
                </div>}
                {page === 3 && <div className='mt-4 mb-4 row'>
                    <div className="fw-600 mb-1 text-primary">Días de turno</div>
                    <div className="small text-gray-600">En cada día de turno se puede definir hasta un máximo de dos breaks o descansos.</div>
                    <CustomInput config={{ multiple: true }} className='mb-3 mt-3 col-6' type="pick" options={DAYS_OPTIONS} data={days} setData={setDays}></CustomInput>
                    <TurnsInput className="mt-4" data={doctorRegistration.availability || {}} setData={(val) => setDoctorState('availability', val)} days={days}></TurnsInput>
                </div>}

                <hr />
                <div className='d-flex justify-content-between align-items-center'>
                    {page !== 0 ? <button onClick={() => setPage(page - 1)} className="btn btn-edit">Volver al paso anterior</button> : <div />}
                    {page !== 3
                        ? <button onClick={() => setPage(page + 1)} className="btn btn-primary">Continuar</button>
                        : <button onClick={handleSubmit} className="btn btn-primary">Guardar</button>
                    }
                </div>
            </div>
        </TitleCard >
    </>
};

export default RegisterEdit