import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styles from '../css/patients.module.css';
import Button from '../utils/Button';
import bigJulia from '../../media/big_nurse.png';
import CustomInput from '../utils/Input';
import { Info } from 'react-feather';
import { setCurrentCard } from '../../actions/homeActions';
import { HOME_CARDS } from '../../reducers/homeReducer';
import { ReduxSaveDescription, resetCurrentDoctor } from '../../actions/patientActions';

const Consultation = () => {
	const dispatch = useDispatch();
	const { user } = useSelector(state => state.user);
	const [consultation, setConsultation] = useState('');
	const [empty, setEmpty] = useState(false);

	const goToWaitingRoom = async () => {
		dispatch(resetCurrentDoctor());
		await dispatch(ReduxSaveDescription({ id: user._id, description: consultation.trim() }))
		dispatch(setCurrentCard(HOME_CARDS.WAITING_ROOM));
	}

	return (
		<section style={{ alignSelf: 'start' }} className={styles.phoneCard}>
			<h1 className="primary-dark">{user.gender === 'M' ? `Sr ${user.name}` : `Sra ${user.name}`}</h1>
			<p>Estamos para ayudarle.</p>
			<div className='mt-2 mb-5'>
				<img className="img-fluid" src={bigJulia} />
			</div>
			{!empty
				? <>
					<h2 className="primary-dark">¿Cuál es el motivo de su consulta?</h2>
					<p>Por favor escriba el motivo de su consulta para poder atenderle lo antes posible.</p>
					<CustomInput config={{ rows: 8 }} className='mt-5' label="Motivo de consulta" placeholder="Escriba su consulta aquí..." type="textarea" data={consultation} setData={(val) => setConsultation(val)}></CustomInput>
					<Button onClick={goToWaitingRoom} className='mt-4 mb-0 w-100' disabled={!consultation} text={"Ir a la sala de espera"}></Button>
				</> : <>
					<h2 className="primary-dark">No hay doctores disponibles</h2>
					<p>Actualmente no hay doctores en línea para realizar consultas virtuales, por favor consulte nuestros horarios de atención.</p>
					<div className={styles.consultationTurns}>
						<Info />
						<div>
							<span>Horario de atención:</span>
							<li><span>8:00 AM - 10:00 PM</span></li>
						</div>
					</div>
				</>
			}
		</section>
	);
}

export default Consultation;