import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import { getDoctorConsultation, getDoctors, updateDoctorImage } from '../actions/usersActions';
import { formatNumberThousands, generateDatetime, getDate, formatPhoneNumber } from '../helpers';
import './css/Profile.css'
import PageTitle from './utils/PageTitle';
import Tag from './utils/Tag';
import TitleCard from './utils/TitleCard';
import Turns from './utils/Turns';
import DoctorImage from './utils/DoctorImage';
import { useRef } from 'react';

const customStyles = {
    headCells: {
        style: {
            fontFamily: "Metropolis",
            color: "#69707A",
            fontWeight: '700',
            padding: '12px', // override the row height
        },
    },
    cells: {
        style: {
            fontFamily: "Metropolis",
            color: "#69707A",
            padding: '12px', // override the row height
        },
    },
};

const paginationComponentOptions = {
    rowsPerPageText: 'Consultas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

const Profile = ({ }) => {
    const { id } = useParams();
    const imgInput = useRef();
    const [doctor, setDoctor] = useState({});
    const [consultations, setConsultations] = useState([]);

    useEffect(() => {
        getDoctor(id);
    }, [id])

    const getDoctor = async (id) => {
        const doc = await getDoctors(id);
        setDoctor(doc.data.data);
        const cons = await getDoctorConsultation(id);
        setConsultations(cons.data.consultation)
    }

    const PatientName = ({ row }) => {
        const patient = row.patient || {};
        return <div>
            <div className="mb-1 text-gray-900"><b>{patient.name} {patient.lastName}</b></div>
            { patient.insurance && <div>{patient.insurance.name}</div>}
        </div>
    }

    const ReportDate = ({ row }) => {
        const date = generateDatetime(row.startDate);
        return <div>
            {date[0].slice(0, -1)} &middot; {date[1]} {date[2]}
        </div>
    }

    const ProfileInput = ({ label, text, className = '' }) => {
        return <div className={className}>
            <div className='small text-gray-500'>{label}</div>
            <div style={{ fontSize: 18, lineHeight: '21px', marginTop: 4 }} className="text-gray-800">{text}</div>
        </div>
    }

    const columns = [
        {
            name: 'Paciente',
            selector: row => (row.patient || {}).name,
            cell: row => <PatientName row={row} />,
            sortable: true,
        },
        {
            name: 'Fecha',
            selector: row => row.startDate,
            cell: row => <ReportDate row={row} />,
            sortable: true,
        },
        {
            name: 'Calificación',
            selector: row => row.rating || 0,
            cell: row => <Tag className="rating" title={`${row.rating || 1}/5`} />,
            center: true,
            sortable: true,
        },
    ];

    const onPhotoChange = async (e) => {
        const response = await updateDoctorImage(doctor._id, e.target.files[0]);
        if (response.status === 200) getDoctor(id);
    }

    return doctor && <>
        <PageTitle title={`${doctor.gender === 'F' ? 'Dra' : 'Dr'} ${doctor.name} ${doctor.lastName || ''}`} date={false}>
            <Link to={`/dashboard/register/${doctor._id}`}>
                <button className="btn btn-primary btn-block"> Editar </button>
            </Link>
        </PageTitle>
        <div className="profile-grid mb-4">
            <TitleCard className="align-self-stretch" title={'Foto de perfil'}>
                <div className='d-flex flex-column align-items-center'>
                    <DoctorImage size={160} src={doctor.photo} gender={doctor.gender} />
                    <div className="small text-gray-500 mt-4">JPG or PNG con tamaño máximo de 5 MB</div>
                    <input ref={imgInput} style={{ display: 'none' }} type="file" accept="image/*" onChange={onPhotoChange} />
                    <button className="btn btn-primary mt-4" onClick={() => imgInput.current.click()}>Cargar nueva foto</button>
                </div>
            </TitleCard>
            <TitleCard title={'Datos de perfil'}>
                <div className='row'>
                    <ProfileInput label={'Nombres'} text={doctor.name} className='col-6 mb-3' />
                    <ProfileInput label={'Apellidos'} text={doctor.lastName} className='col-6 mb-3' />
                    <ProfileInput label={'Cédula'} text={formatNumberThousands((doctor.ci || '').toString())} className='col-6 mb-3' />
                    <ProfileInput label={'Fecha de nacimiento'} text={getDate(doctor.birthDate)} className='col-6 mb-3' />
                    <ProfileInput label={'Sexo'} text={doctor.gender === 'F' ? 'Femenino' : 'Masculino'} className='col-12 mb-3' />
                    <ProfileInput label={'Correo electrónico'} text={doctor.email} className='col-6 mb-3' />
                    <ProfileInput label={'Teléfono de contacto'} text={formatPhoneNumber(doctor.numberPhone)} className='col-6 mb-3' />
                    <ProfileInput label={'Especialidad'} text={doctor.skill} className='col-4 mb-0' />
                    <ProfileInput label={'Colegio médico (C.M)'} text={doctor.cm} className='col-4 mb-0' />
                    <ProfileInput label={'M.P.P.S'} text={doctor.mpps} className='col-4 mb-0' />
                </div>
            </TitleCard>
        </div>
        <div className="profile-grid">
            <TitleCard title={'Programación de turnos'}>
                {doctor.availability && <Turns data={doctor.availability} />}
            </TitleCard>
            <TitleCard title={'Últimas consultas'}>
                <DataTable
                    customStyles={customStyles}
                    paginationComponentOptions={paginationComponentOptions}
                    pagination
                    columns={columns}
                    data={consultations}
                />
            </TitleCard>
        </div>
    </>
};

export default Profile