import React, { Fragment } from 'react'
import { useSelector } from 'react-redux';
import LoginPass from './LoginPass';
import MyData from './MyData';

const LogOrRegister = () => {
    const usersReducer = useSelector(state=>state.user);
    const {authenticated} = usersReducer;
    return ( 
        <Fragment>
            {authenticated  ?  <LoginPass /> : <MyData />}
        </Fragment>
     );
}
 
export default LogOrRegister;