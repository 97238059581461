// SocketProvider.js
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';
import SocketContext from './socketContext';

const SOCKET_SERVER_URL = process.env.REACT_APP_BACKEND_URL_SOCKET || "http://localhost:4000";

export const SocketProvider = ({ children }) => {
  const { user, isLogged } = useSelector(state => state.user);
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    let newSocket;
    const ID = user.id || user._id;
    if (isLogged && ID && !socket) {
      newSocket = io(SOCKET_SERVER_URL, {
        query: { userId: ID }
      });

      newSocket.on('connect', () => {
        console.log('Connected to socket server');
        setIsConnected(true);
      });

      newSocket.on('disconnect', () => {
        console.log('Disconnected from socket server');
        setIsConnected(false);
      });

      setSocket(newSocket);
    }
  }, [isLogged, user, socket]);

  return (
    <SocketContext.Provider value={{ socket, isConnected }}>
      {children}
    </SocketContext.Provider>
  );
};