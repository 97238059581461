export const LOADING = 'LOADING';
export const AUTH_DOCTOR_SUCCESS ='AUTH_DOCTOR_SUCCESS';
export const AUTH_DOCTOR_ERROR ='AUTH_DOCTOR_ERROR'
export const GET_DOCTOR_DATA_SUCCESS ='GET_DOCTOR_DATA_SUCCESS';
export const GET_DOCTOR_DATA_ERROR ='GET_DOCTOR_DATA_ERROR';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR'; 
export const NULL_ERROR = 'NULL_ERROR';
export const RESET_USERS = 'RESET_USERS';

//WHEN THE USER LOGIN WITH HIS PASSWORD
export const LOGIN_DR_SUCCESS = 'LOGIN_DR_SUCCESS';
export const LOGIN_DR_ERROR = 'LOGIN_DR_ERROR';

//MANAGE THE QUEUE FOR PATIENTS AND DOCTORS
export const ADD_PATIENT = 'ADD_PATIENT';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const REMOVE_PATIENT = 'REMOVE_PATIENT';
export const PASS_PATIENT = 'PASS_PATIENT';
export const ADD_DOCTOR = 'ADD_DOCTOR';
export const REMOVE_DOCTOR = 'REMOVE_DOCTOR';

// PATIENT ACTIONS
export const CURRENT_PATIENT = 'CURRENT_PATIENT';
export const RESET_CURRENT_PATIENT = 'RESET_CURRENT_PATIENT';
export const LAST_PATIENT = 'LAST_PATIENT';

// PATIENT ACTIONS
export const DOCTOR_METRICS = 'DOCTOR_METRICS';

// CONSULTATION ACTIONS
export const FINISHED_CONSULTATION = 'FINISHED_CONSULTATION';
export const RESET_FINISHED_CONSULTATION = 'RESET_FINISHED_CONSULTATION';

// REPORT TYPES
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const REPORT_LOADING = 'REPORT_LOADING';
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR';

// REPORT TYPES
export const DOCTOR_REGISTRATION = 'DOCTOR_REGISTRATION';
export const DOCTOR_TURN_ACTIVE = 'DOCTOR_TURN_ACTIVE';

//PATIENT ACTIONS
export const SAVE_DESCRIPTION_SUCCESS = 'SAVE_DESCRIPTION_SUCCESS';
export const SAVE_DESCRIPTION_ERROR = 'SAVE_DESCRIPTION_ERROR';
export const UPDATE_CONSULTATION = 'UPDATE_CONSULTATION';
export const UPDATE_CONSULTATION_HISTORY = 'UPDATE_CONSULTATION_HISTORY';
// DOCTOR ACTIONS
export const CURRENT_DOCTOR = 'CURRENT_DOCTOR';
export const RESET_CURRENT_DOCTOR = 'RESET_CURRENT_DOCTOR';