import {
    LOADING,
    AUTH_DOCTOR_SUCCESS,
    AUTH_DOCTOR_ERROR,
    GET_DOCTOR_DATA_SUCCESS,
    GET_DOCTOR_DATA_ERROR,
    LOGIN_ERROR,
    SET_PASSWORD_SUCCESS,
    SET_PASSWORD_ERROR,
    LOGIN_DR_SUCCESS,
    LOGIN_DR_ERROR,
    NULL_ERROR,
    RESET_CURRENT_PATIENT,
    CURRENT_PATIENT,
    RESET_FINISHED_CONSULTATION,
    FINISHED_CONSULTATION,
    LAST_PATIENT,
    DOCTOR_METRICS,
    DOCTOR_REGISTRATION,
    DOCTOR_TURN_ACTIVE,
    RESET_USERS
} from '../types/index';
import clientAxios from '../config/axios';

export function setLoading() {
    return dispatch => {
        dispatch({
            type: LOADING
        })
    }
};
export function nullError() {
    return dispatch => {
        dispatch({
            type: NULL_ERROR
        })
    }
}
export function resetUsers() {
    return dispatch => {
        dispatch({
            type: RESET_USERS
        })
    }
}

export function setCiUser(ci) {
    return async function (dispatch) {
        try {
            dispatch(setLoading());
            const response = await clientAxios.post('/api/auth', { ci });
            dispatch({
                type: AUTH_DOCTOR_SUCCESS,
                payload: {
                    id: response.data.id,
                    name: response.data.name,
                    lastName: response.data.lastName,
                    gender: response.data.gender,
                    auth: response.data.isAuth,
                    msg: response.data.msg
                }
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: AUTH_DOCTOR_ERROR,
                payload: error.response.data.msg
            });
        }
    }
}

export function loginPass(payload) {
    return async function (dispatch) {
        try {
            dispatch(setLoading());
            const response = await clientAxios.post('/api/auth/login-pass', payload);
            dispatch({
                type: LOGIN_DR_SUCCESS,
                payload: response.data
            })
        } catch (error) {
            dispatch({
                type: AUTH_DOCTOR_ERROR,
                payload: error.response.data.msg
            });
        }
    }
}

export function getDataDoctor(id) {
    return async dispatch => {
        try {
            const response = await clientAxios.get(`/api/auth?id=${id}`)
            dispatch({
                type: GET_DOCTOR_DATA_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: GET_DOCTOR_DATA_ERROR,
                payload: error.response.data.msg
            });
            dispatch(setLoading());
        }
    }
};

export function userAuthenticated(id) {
    return async dispatch => {
        try {
            getDataDoctor(id)
        } catch (error) {
            dispatch({
                type: LOGIN_ERROR
            })
        }
    }
}

export function registerNewUserByCi(userData) {
    return async (dispatch) => {
        try {
            dispatch(setLoading());
            const response = await clientAxios.post('/api/auth', { ci: userData.ci, nationality: userData.nationality });
            const patient = {
                id: response.data.id,
                name: response.data.name,
                lastName: response.data.lastName
            }
            dispatch({
                type: AUTH_DOCTOR_SUCCESS,
                payload: { ...patient, msg: response.data.msg, auth: response.data.isAuth }
            });
        } catch (error) {
            dispatch({
                type: AUTH_DOCTOR_ERROR,
                payload: error.response.data.msg
            });
        }
    }
};

//Setting password
export function setPasswordRedux(data) {
    return async dispatch => {
        try {
            dispatch(setLoading());
            const response = await clientAxios.patch(`/api/auth/pass/${data.id}`, { type: data.type, password: data.password });
            dispatch({
                type: SET_PASSWORD_SUCCESS,
                payload: response.data
            })
            dispatch(setLoading());
        } catch (error) {
            dispatch({
                type: SET_PASSWORD_ERROR
            });
        }
    }
}
//Login with password
export function loginWithPassword(data, dr = true) {
    return async dispatch => {
        try {
            dispatch(setLoading());
            const response = await clientAxios.post(`/api/${dr ? 'loginDr' : 'loginPt'}`, data);
            dispatch({
                type: LOGIN_DR_SUCCESS,
                payload: response.data
            })
        } catch (error) {
            dispatch({
                type: LOGIN_DR_ERROR,
                payload: error.response.data.msg
            })
            dispatch(setLoading());
        }
    }
}

export function authLogin(data) {
    return async dispatch => {
        try {
            dispatch(setLoading());
            const response = await clientAxios.post('/api/auth/login', data);
            dispatch({
                type: AUTH_DOCTOR_SUCCESS,
                payload: response.data
            });
            dispatch(setLoading());
        } catch (error) {
            dispatch({
                type: LOGIN_DR_ERROR,
                payload: error.response.data.msg
            })
        }
    }
}

//Login with password
export function getMetrics(id) {
    return async dispatch => {
        try {
            dispatch(setLoading());
            const response = await clientAxios.get('/api/consultation/dr/' + id);
            const { currentMonth, lastMonth } = response.data.data;

            const metrics = { currentMonth, lastMonth };
            if (lastMonth) {
                metrics['patientTrend'] = Math.round((currentMonth['totalPatients'] * 100) / (lastMonth['totalPatients'] || 1));
                metrics['patientTrendDirection'] = currentMonth['totalPatients'] === lastMonth['totalPatients']
                    ? '' : currentMonth['totalPatients'] > lastMonth['totalPatients'] ? 'up' : 'down';

                metrics['durationTrend'] = Math.round((currentMonth['totalDuration'] * 100) / (lastMonth['totalDuration'] || 1));
                metrics['durationTrendDirection'] = currentMonth['totalDuration'] === lastMonth['totalDuration']
                    ? '' : currentMonth['totalDuration'] > lastMonth['totalDuration'] ? 'up' : 'down';

                metrics['doctorsTrend'] = Math.round((currentMonth['totalDoctors'] * 100) / (lastMonth['totalDoctors'] || 1));
                metrics['doctorsTrendDirection'] = currentMonth['totalDoctors'] === lastMonth['totalDoctors']
                    ? '' : currentMonth['totalDoctors'] > lastMonth['totalDoctors'] ? 'up' : 'down';
            }
            dispatch({ type: DOCTOR_METRICS, payload: metrics });
        } catch (error) {
            dispatch(setLoading());
        }
    }
}

export async function getPatient(id) {
    return clientAxios.get('/api/patients/' + id);
}

export async function getDoctors(id = '', params = {}) {
    return clientAxios.get('/api/doctors/' + id, { params });
}

export async function createDoctor(payload) {
    return clientAxios.post(`/api/auth/doctor`, payload);
}

export async function getDoctorConsultation(id) {
    return clientAxios.get(`/api/consultation/pt?doctor=${id}`);
}

export async function updateDoctorImage(id, photo) {
    const formData = new FormData();
    formData.append('photo', photo);
    return clientAxios.put('/api/doctors/' + id, formData, {
        transformRequest: (data, headers) => {
            return formData;
        },
        responseType: "json",
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export function updatePatientImage(id, photo) {
    return async dispatch => {
        const formData = new FormData();
        formData.append('photo', photo);
        const response = await clientAxios.put('/api/patients/' + id, formData, {
            transformRequest: (data, headers) => {
                return formData;
            },
            responseType: "json",
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.status === 200) {
            dispatch({
                type: LOGIN_DR_SUCCESS,
                payload: response.data
            })
        }
    }
}

export async function uploadPhoto(photo) {
    const formData = new FormData();
    formData.append('photo', photo);
    return clientAxios.post('/api/doctors/photo', formData, {
        transformRequest: (data, headers) => {
            return formData;
        },
        responseType: "json",
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export async function updateDoctor(id = '', payload) {
    return clientAxios.put('/api/doctors/' + id, payload);
}

export function setCurrentPatient(patient) {
    return dispatch => {
        dispatch({
            type: CURRENT_PATIENT,
            payload: patient
        })
    }
};

export function setLastPatient(patient) {
    return dispatch => {
        dispatch(resetCurrentPatient());
        dispatch({
            type: LAST_PATIENT,
            payload: patient
        })
    }
};

export function setDoctorTurnActive(boolean) {
    return dispatch => {
        dispatch({
            payload: boolean,
            type: DOCTOR_TURN_ACTIVE,
        })
    }
};

export function setDoctorRegistration(payload) {
    return dispatch => {
        dispatch(resetCurrentPatient());
        dispatch({
            payload,
            type: DOCTOR_REGISTRATION,
        })
    }
};

export function resetCurrentPatient() {
    return dispatch => {
        dispatch({ type: RESET_CURRENT_PATIENT })
    }
};

export function finishConsultation() {
    return dispatch => {
        dispatch({ type: FINISHED_CONSULTATION })
    }
};

export function resetFinishedConsultation() {
    return dispatch => {
        dispatch({ type: RESET_FINISHED_CONSULTATION })
    }
};
