import React from 'react';
import styles from './css/Alert.module.css'
const Alert = ({msg,variant}) => {
    let variantStyle = variant === 'success' ? styles.success : styles.danger
    return ( 
        <div className={`${variantStyle} ${styles.defaultStyle}`}>
            {msg}
        </div>
     );
}
 
export default Alert;