import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styles from '../css/patients.module.css';
import Button from '../utils/Button';
import bigJulia from '../../media/big_nurse.png';
import CustomInput from '../utils/Input';
import { Info } from 'react-feather';
import DoctorData from './components/DoctorData';
import Rating from './components/Rating';
import { resetHome, setCurrentCard } from '../../actions/homeActions';
import { getConsultationTime } from '../../helpers';
import { getConsultation, getConsultations, updateConsultation } from '../../actions/patientActions';
import { HOME_CARDS } from '../../reducers/homeReducer';
import { showInfoModal } from '../../actions/modalActions';

const Review = () => {
	const dispatch = useDispatch();
	const [comments, setComments] = useState('');
	const { user } = useSelector(state => state.user);
	const { consultation, doctor } = useSelector(state => state.queue);
	const [duration, setDuration] = useState(0);
	const [rating, setRating] = useState(doctor.rating || 0);

	useEffect(() => {
		dispatch(getConsultation(consultation._id));
	}, [])

	useEffect(() => {
		if (consultation) {
			const endDate = consultation.endDate || (new Date()).toISOString();
			if (endDate && consultation.startDate) {
				setDuration((new Date(endDate) - new Date(consultation.startDate))) // Duration in seconds
			}
		}
	}, [consultation])

	const sendComment = () => {
		dispatch(updateConsultation(consultation._id, user._id, { duration, rating, comments }));
		dispatch(showInfoModal(
			'Gracias por confiar en Compañía Seguros',
			`Usted ha dado un paso por su salud, esperamos haber sido de ayuda hoy. `
		));
		dispatch(resetHome());
	}

	return (
		<section style={{ alignSelf: 'start' }} className={styles.phoneCard}>
			<div className={`${styles.insetPhoneCard} patternBg`}>
				<DoctorData doctor={doctor} vertical tagClass='primary-dark' tagTitle={`Duración de la consulta: ${getConsultationTime(duration / 1000)}`} />
			</div>
			<div className='text-center my-4'>
				<h2 className="primary-dark mb-4">¿Cómo fue su experiencia?</h2>
				<Rating rating={rating} setRating={setRating} />
			</div>
			<CustomInput config={{ rows: 8 }} className='mt-5' label="Comentarios (opcional)" placeholder="Escriba su comentario aquí..." type="textarea" data={comments} setData={(val) => setComments(val)}></CustomInput>
			<Button onClick={() => sendComment()} className='mt-4 mb-0 w-100' text={"Enviar comentarios"}></Button>
		</section>
	);
}

export default Review;