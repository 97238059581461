import React, {useEffect} from 'react'
import styles from './modalsCSS/Modal.module.css';
import stylesModal from './modalsCSS/ModalWait.module.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../Spinner';
import {X} from 'react-feather';
import useMediaStream from '../../../hooks/useMediaStream';

function ModalWait({setShowModal, showModal, title, body, ready}) {
  let navigate = useNavigate();
  useMediaStream();

  const closeOnEscapeKeyDown = e => {
    if((e.charCode || e.keyCode) === 27){
      setShowModal({...showModal,modalWait:false})
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown)
    return function cleanup(){
      document.body.removeEventListener('keydown',closeOnEscapeKeyDown)
    }
  },[])

      return (
      <div className={`${styles.modal} ${stylesModal.modalW} ${showModal.modalWait && styles.animation}`} onClick={() => setShowModal(false)}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <div className={`${styles.modalHeader} d-flex flex-row justify-content-between`}>
          <h4 className={`${styles.modalTitle} text-start`}>
              <b>{title}</b>
          </h4>
          <div className='btn m-0 p-0'>
            <X 
            color={'black'}
            style={{ verticalAlign: 'middle' }}
            onClick={() => setShowModal({...showModal,modalWait:false})}
            />
          </div>
        </div>

        <div className={`${styles.modalBody} text-center`}>
          {body}
        </div>

        <div className={`${styles.modalFooter} d-flex justify-content-center`}>
            <button 
            className='btn btn-primary w-50'
            onClick={ready ? () => navigate('/dashboard/meet') : null}>
              {
                ready ? 
                'Comenzar'
                :
                 <Spinner />
              }
            </button>
        </div>
          
      </div>
    </div>
    )
}

export default ModalWait