import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducer from './reducers/index';
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage";


const persistConfig = {
    key: 'main-root',
    storage
}

const persistedReducer = persistReducer(persistConfig, reducer)
const ENV = process.env.REACT_APP_ENV;

const store = createStore(
    //ENV === 'local' ? persistedReducer : reducer,
    persistedReducer,
    compose(applyMiddleware(thunk),
        typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' ?
            window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);

const Persistor = persistStore(store)
export { Persistor }
export default store
