import { HIDE, SHOW_INFO_MODAL } from '../types/modal';

export function hideModal() {
    return dispatch => {
        dispatch({
            type: HIDE
        })
    }
}

export function showInfoModal(title, text, btnText, next = undefined) {
    return async function (dispatch) {
        dispatch({
            type: SHOW_INFO_MODAL,
            payload: { title, text, btnText, next }
        });
    }
}