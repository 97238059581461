import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Login from "./components/Login";
import NewPassword from "./components/NewPassword";
import PrivateRoute from "./components/PrivateRoute";
import Private from "./components/Private";
import LogOrRegister from "./components/LogOrRegister";
import Dashboard from "./components/Dashboard";
import PatientsDashboard from "./components/patients/Dashboard";
import Room from "./components/Room";
import LoginPass from "./components/LoginPass";
import UserDashboard from "./components/UserDashboard";
import Doctors from "./components/Doctors";
import Profile from "./components/Profile";
import RegisterEdit from "./components/RegisterEdit";
import LoginPatients from "./components/patients/Login";
import LoginOrRegister from "./components/patients/LoginOrRegister";
import Home from "./components/patients/Home";
import ModalPatients from "./components/utils/modals/ModalPatients";
import RecoverPassword from "./components/patients/RecoverPassword";
import PatientRoom from "./components/patients/PatientRoom";


function App() {
  return (<>
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/insurance" element={<Login type='insurance' />} />
        <Route path="/admin" element={<Login type='admin' />} />

        {/* Private patients routes */}
        <Route path="/patients" element={<Private redirect="/patients/login"><PatientsDashboard /></Private>}>
          <Route path="/patients" element={<Home />} />
          <Route path="/patients/meet" element={<PatientRoom />} />
        </Route>
        {/* Public patients routes */}
        <Route path="/patients" element={<PatientsDashboard />}>
          <Route path="/patients/login-register" element={<LoginOrRegister />} />
          <Route path="/patients/login" element={<LoginPatients />} />
          <Route path="/patients/recover" element={<RecoverPassword />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/logDr" element={<LogOrRegister />} />
          <Route path="/insurance/login" element={<LoginPass type='insurance' />} />
          <Route path="/auth/pass" element={<NewPassword />} />
          <Route path="/insurance/auth/pass" element={<NewPassword type='insurance' />} />
          <Route path="/dashboard/meet" element={<Room />} />
        </Route>
        <Route path="/dashboard" element={<Private><Dashboard /></Private>}>
          <Route path="/dashboard" element={<UserDashboard />} />
          <Route path="/dashboard/doctors" element={<Doctors />} />
          <Route path="/dashboard/doctors/:id" element={<Profile />} />
          <Route path="/dashboard/register" element={<RegisterEdit />} />
          <Route path="/dashboard/register/:id" element={<RegisterEdit />} />
        </Route>
      </Routes>
      <ModalPatients />
    </Router>
  </>);
}

export default App;