import { HIDE, SHOW_INFO_MODAL } from "../types/modal";


const initialState = {
    show: false,
    title: null,
    text: null,
    btnText: null,
    next: () => {},
}

export default function modalReducer(state = initialState, action) {
    switch (action.type) {
        case HIDE:
            return {
                ...initialState
            };
        case SHOW_INFO_MODAL:
            return {
                ...state,
                ...action.payload,
                show: true,
            };
        default:
            return state;
    }
}