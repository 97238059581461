import { RESET, SET_CARD } from '../types/homecards';

export function resetHome() {
    return dispatch => {
        dispatch({
            type: RESET
        })
    }
}

export function setCurrentCard(current) {
    return async function (dispatch) {
        dispatch({
            type: SET_CARD,
            payload: { current }
        });
    }
}