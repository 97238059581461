import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store, { Persistor } from './store';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { SocketProvider } from './hooks/socketProvider';
const root = ReactDOM.createRoot(document.getElementById('root'));
const ENV = process.env.REACT_APP_ENV;
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={Persistor}>
      <SocketProvider>
        <App />
      </SocketProvider>
    </PersistGate>
    {/*
    {ENV === 'local' ?
    <PersistGate loading={null} persistor={Persistor}>
    <App />
    </PersistGate>
    : <App />}
    
    */}
  </Provider>
);

/*
 
*/
reportWebVitals();
