import React, { useState } from 'react'
import TitleCard from './utils/TitleCard';
import PageTitle from './utils/PageTitle';
import loadingGif from '../assets/img/loading.gif';
import successImg from '../assets/img/success.png';
import './css/Reports.css'
import Tag from './utils/Tag';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { getReport } from '../actions/reportActions';
import { formatNumberThousands, generateDate, generateDatetime, getConsultationTime, getMonthRange } from '../helpers';
import CustomInput from './utils/Input';

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000';
const customStyles = {
	headCells: {
		style: {
			fontFamily: "Metropolis",
			color: "#69707A",
			fontWeight: '700',
			padding: '12px', // override the row height
		},
	},
	cells: {
		style: {
			fontFamily: "Metropolis",
			color: "#69707A",
			padding: '12px', // override the row height
		},
	},
};
const colectiveOptions = [{ key: '628b980597a21f5025df2894', name: 'aseguradora UPA' }];
const doctorOptions = [{ key: '628c632aadd01675d17e838e', name: 'Antonio Rodriguez' }];

const Reports = ({ type }) => {
	const { report, loading } = useSelector(state => state.reports);
	const [range, setRange] = useState(getMonthRange(new Date()));
	const [colective, setColective] = useState();
	const [doctor, setDoctor] = useState();
	const dispatch = useDispatch();

	const generateReport = () => {
		dispatch(getReport({ doctor, colective, startDate: range[0], endDate: range[1] }));
	}

	const paginationComponentOptions = {
		rowsPerPageText: 'Consultas por página',
		rangeSeparatorText: 'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos',
	};

	function downloadCSV() {
		const uri = `${BASE_URL}/api/reports/csv`;
		const name = `export`
		var link = document.createElement("a");
		link.setAttribute('download', name);
		link.href = uri;
		document.body.appendChild(link);
		link.click();
		link.remove();
	}

	const PatientName = ({ row }) => {
		const patient = row.patient || {};
		return <div>
			<div className="mb-1"><b>{patient.name} {patient.lastName}</b></div>
			<div>{patient.nationality}-{formatNumberThousands(patient.ci.toString())}</div>
		</div>
	}

	const ReportDate = ({ row }) => {
		const date = generateDatetime(row.startDate);
		return <div>
			{date[0].slice(0, -1)} &middot; {date[1]} {date[2]}
		</div>
	}

	const selectedDates = () => {
		if (range[0] && range[1]) {
			const startDate = generateDate(range[0]);
			const endDate = generateDate(range[1]);
			return <div> {startDate[1]} {startDate[2]} {startDate[3]} - {endDate[1]} {endDate[2]} {endDate[3]}</div>
		}
	}

	const getColective = () => {
		if (colective) {
			const optionLabel = colective === 'all' ? 'Todos' : (colectiveOptions.find(option => option.key === colective) || {}).name;
			return <div> {optionLabel}</div>
		}
	}

	const getDoctor = () => {
		if (doctor) {
			const optionLabel = doctor === 'all' ? 'Todos' : (doctorOptions.find(option => option.key === doctor) || {}).name;
			return <div> {optionLabel}</div>
		}
	}

	const ReportDuration = ({ row }) => {
		return getConsultationTime((row.duration || 0) * 60) || '--'
	}

	const columns = [
		{
			name: 'Paciente',
			selector: row => (row.patient || {}).name,
			cell: row => <PatientName row={row} />,
			sortable: true,
		},
		{
			name: 'Colectivo',
			selector: row => ((row.patient || {}).insurance || {}).name,
			sortable: true,
		},
		{
			name: 'Fecha',
			selector: row => row.startDate,
			cell: row => <ReportDate row={row} />,
			sortable: true,
		},
		{
			name: 'Duración',
			selector: row => row.duration || 0,
			cell: row => <ReportDuration row={row} />,
			sortable: true,
		},
	];

	return <>
		<PageTitle title="Consultas" />
		<div className='card mb-4'>
			<div className="card-body p-5 row">
				<div className="col">
					<h2 className="fw-600 text-primary">Reportes de consultas</h2>
					<p className="bs-gray-600 mb-5">Selecciona los datos para generar el reporte. <br /> Una vez generado podrás descargarlo en formato CSV.</p>

					<CustomInput config={{ selectRange: true }} className="mb-3" type="datepicker" label="Rango de tiempo" data={range} setData={setRange}></CustomInput>
					<CustomInput className="mb-3" type="select" options={colectiveOptions} label="Colectivo" data={colective} setData={setColective}></CustomInput>
					{type === 'admin' && <CustomInput className="mb-3" type="select" options={doctorOptions} label="Doctor" data={doctor} setData={setDoctor}></CustomInput>}

					<button className="btn btn-primary p-3 mt-4" onClick={generateReport} disabled={!range || !colective}>
						Generar Reporte
					</button>
				</div>
				<div className="col">
					<div className='d-flex flex-column h-100 justify-content-center align-items-center'>
						{loading && <>
							<div className="loadingGif">
								<img src={loadingGif} alt='' />
							</div>
							<p className="small text-gray-600">Generando reporte...</p>
						</>}
						{report && !loading && <>
							<img src={successImg} alt='' />
							<p className="small text-gray-600">Reporte generado con éxito</p>
						</>}
						{range[0] && range[1] && <Tag title={selectedDates()}></Tag>}
						{colective && <Tag title={getColective()}></Tag>}
						{doctor && <Tag title={getDoctor()}></Tag>}

						{report && !loading && <div className='mt-3' onClick={downloadCSV}>
							<a href="#">Descargar CSV</a>
						</div>}
					</div>
				</div>
			</div>
		</div>
		{report && <TitleCard title={'Reporte de consultas'}>
			<div className="d-flex" style={{ gap: 8 }}>
				{range[0] && range[1] && <Tag title={selectedDates()}></Tag>}
				{colective && <Tag title={getColective()}></Tag>}
				{doctor && <Tag title={getDoctor()}></Tag>}
			</div>
			<div>
				<DataTable
					customStyles={customStyles}
					paginationComponentOptions={paginationComponentOptions}
					pagination
					columns={columns}
					data={report}
				/>
			</div>
		</TitleCard>}
	</>
};

export default Reports;