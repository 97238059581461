import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import StreamArea from "../StreamArea";
import styles from '../css/patients.module.css';
import DoctorData from "./components/DoctorData";
import { setCurrentCard } from "../../actions/homeActions";
import { HOME_CARDS } from "../../reducers/homeReducer";
import VideoCall from "../VideoCall";

const { RTCPeerConnection } = window;
let peerConnections = {};
const config = {
  iceServers: [
    {
      urls: ["stun:stun.l.google.com:19302"]
    }
  ]
};

let watcherInterval;
let offerInterval;

const restartPeer = (peerConnection) => {
  switch (peerConnection.iceConnectionState) {
    case "disconnected":
    case "failed":
      peerConnection.restartIce()
  }
}

function PatientRoom() {
  const { doctor, consultation } = useSelector(state => state.queue);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onWatcher = (id, socket, video) => {
    console.log("Watcher joining", id, doctor.id)
    socket.emit('join-stream', doctor.id);
    const peerConnection = new RTCPeerConnection(config);
    peerConnections[`${id}-offer`] = peerConnection;

    if (!video.current.srcObject) throw new Error('No local media detected');

    let stream = video.current.srcObject;

    peerConnection.addStream(stream);
    stream.getTracks().forEach((track) => {
      return stream.addTrack(track)
    });

    peerConnection.onicecandidate = event => {
      if (event.candidate) {
        console.log("WATCHER Patient Room Candidate", doctor.id)
        socket.emit("candidate", doctor.id, { message: event.candidate, role: 'watcher' });
      }
    };

    peerConnection
      .createOffer({ iceRestart: true })
      .then(sdp => peerConnection.setLocalDescription(sdp))
      .then(() => {
        console.log("WATCHER Patient Room Offer", doctor.id)
        socket.emit("offer", doctor.id, peerConnection.localDescription);
      });

    peerConnection.onconnectionstatechange = function (event) {
      switch (peerConnection.connectionState) {
        case "disconnected":
        case "failed":
          break;
      }
    }
    peerConnection.oniceconnectionstatechange = function () {
      console.log('ICE state: ', peerConnection.iceConnectionState);
      switch (peerConnection.iceConnectionState) {
        case "disconnected":
        case "failed":
          watcherInterval = setInterval(restartPeer(peerConnection), 1000);
          break;
        default:
          if (watcherInterval) clearInterval(watcherInterval)
      }
    }
  }

  const onOffer = (id, description, socket, video) => {
    console.log("Stream accepted", id)
    const peerConnection = new RTCPeerConnection(config);
    peerConnections[`${id}-watcher`] = peerConnection;
    console.log(peerConnections);
    peerConnection
      .setRemoteDescription(description)
      .then(() => peerConnection.createAnswer())
      .then((sdp) => peerConnection.setLocalDescription(sdp))
      .then(() => {
        console.log("OFFER Patient Room answer", doctor.id)
        socket.emit("answer", doctor.id, { message: peerConnection.localDescription, role: 'offer' });
      });
    peerConnection.onaddstream = (event) => {
      video.current.srcObject = event.stream;
      // IMPORTANT: USER MUST CLICK TO BE ABLE TO SEE THE VIDEO
      video.current.onloadedmetadata = function (e) {
        video.current.play();
      };
    };

    peerConnection.oniceconnectionstatechange = function () {
      console.log('ICE state: ', peerConnection.iceConnectionState);
      switch (peerConnection.iceConnectionState) {
        case "disconnected":
        case "failed":
          offerInterval = setInterval(restartPeer(peerConnection), 1000);
          break;
        default:
          if (offerInterval) clearInterval(offerInterval)
      }
    }

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        console.log("OFFER Patient Room Candidate", doctor.id)
        socket.emit("candidate", doctor.id, { message: event.candidate, role: 'offer' });
      }
    };

    peerConnection.onconnectionstatechange = function (event) {
      switch (peerConnection.connectionState) {
        case "disconnected":
        case "failed":
          /*
          video.current.srcObject = null
          peerConnection.close();
          delete peerConnections[`${id}-watcher`];
          */
          peerConnection.restartIce()
          break;
      }
    }
  }

  const onCandidate = (id, candidate) => {
    peerConnections[id].addIceCandidate(new RTCIceCandidate(candidate));
  }

  const onAnswer = (id, description) => {
    peerConnections[id].setRemoteDescription(description);
  }

  const onEndStream = () => {
    for (const id in peerConnections) peerConnections[id].close()
    dispatch(setCurrentCard(HOME_CARDS.CONSULTATION_FINISHED));
    navigate('/patients');
  }
  return (
    <div className={styles.patientRoom}>
      <VideoCall callId={doctor.id} onEndStream={onEndStream} />
      {/*
      <StreamArea
        patients
        onOffer={onOffer}
        onAnswer={onAnswer}
        onWatcher={onWatcher}
        onEndStream={onEndStream}
        onCandidate={onCandidate} />
      */}
      <div className={styles.floatingDoctorLabel}>
        <div className="patternBg py-3 px-4">
          <DoctorData doctor={doctor} />
        </div>
      </div>
    </div>
  )
}

export default PatientRoom;
