import React, { useEffect, useState } from 'react';
import { generateDate } from '../../helpers';
import './css/PageTitle.css'

const PageTitle = ({ title, subtitle, date = true, children }) => {
  const [strDate, setStrDate] = useState(generateDate());
  useEffect(() => {
    let timer = setInterval(() => setStrDate(generateDate()), 30000);
    return function cleanup() {
      clearInterval(timer)
    }
  });

  return (
    <div className="d-flex justify-content-between align-items-sm-center flex-column flex-sm-row mb-4">
      <div className="me-4 mb-3 mb-sm-0">
        <h1 className="fw-600 fs-24 mb-0">{title}</h1>
        {subtitle && <div className="small">{subtitle}</div>}
        {date && <div className="small">
          <span className="fw-600 text-primary">{strDate[0][0].toUpperCase()}{strDate[0].slice(1, -1)} &#32;</span>
          &middot; {strDate[1]} {strDate[2]} {strDate[3]} &middot; {strDate[5]} {strDate[6]} {strDate[7]}
        </div>}
      </div>
      <div>
        {children}
      </div>
    </div>
  );
}

export default PageTitle;