import {
    ADD_DOCTOR,
    REMOVE_DOCTOR,
    ADD_PATIENT,
    REMOVE_PATIENT,
    PASS_PATIENT,
    SAVE_DESCRIPTION_SUCCESS,
    UPDATE_CONSULTATION,
    UPDATE_CONSULTATION_HISTORY,
    CURRENT_DOCTOR,
    RESET_CURRENT_DOCTOR
} from '../types/index';

const initialState = {
    patients: [],
    errorQueue: null,
    consultations: [],
    consultation: {},
    doctor: {},
    doctors: []
}
export default function queueReducer(state = initialState, action) {
    switch (action.type) {
        case CURRENT_DOCTOR:
            return {
                ...state,
                doctor: action.payload
            };
        case RESET_CURRENT_DOCTOR:
            return {
                ...state,
                doctor: {
                    name: null,
                    lastName: null,
                    gender: null,
                    id: null,
                    mpps: null,
                    cm: null,
                    photo: null
                },
            };
        case SAVE_DESCRIPTION_SUCCESS:
            return {
                ...state,
                consultation: action.payload.consultation,
                msg: action.payload.msg
            };
        case UPDATE_CONSULTATION:
            return {
                ...state,
                consultation: action.payload.consultation,
                msg: action.payload.msg
            };
        case UPDATE_CONSULTATION_HISTORY:
            return {
                ...state,
                consultations: action.payload.consultations,
                msg: action.payload.msg
            };
        case ADD_PATIENT:
        case REMOVE_PATIENT:
        case PASS_PATIENT:
            return {
                ...state,
                patients: action.payload
            };

        case ADD_DOCTOR:
        case REMOVE_DOCTOR:
            return {
                ...state,
                doctors: action.payload
            };
        default:
            return state;
    }
}