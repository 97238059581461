import React, { useEffect, useState } from "react";
import { Star, AlertTriangle } from "react-feather";
import classes from '../../css/patients.module.css';

const ErrorMessage = ({ message }) => {
  return message && message !== 'Autenticación exitosa' && <div className={classes.errorMessage}>
    <div className={classes.warning}><AlertTriangle stroke="white"/></div>
    <p>{message}</p>
  </div >;
}

export default ErrorMessage;