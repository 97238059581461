import React, { useState, useEffect } from 'react'
import { ChevronRight } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { showInfoModal } from '../../actions/modalActions';
import { getConsultations, setCurrentDoctor } from '../../actions/patientActions';
import { consultationTime } from '../../helpers';
import { HOME_CARDS } from '../../reducers/homeReducer';
import styles from '../css/patients.module.css';
import DoctorImage from '../utils/DoctorImage';
import ModalConsultation from '../utils/modals/ModalConsultation';
import Rating from './components/Rating';
import Skeleton from './components/Skeleton';
import Consultation from './Consultation';
import LoginContainer from './LoginContainer';
import Profile from './Profile';
import Review from './Review';
import WaitingRoom from './WaitingRoom';

const ConsultationItem = ({ item, onClick }) => {
	return <div className={styles.consultationCard} onClick={onClick}>
		<div style={{ flexGrow: 1 }}>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 16, gap: 16 }}>
				<DoctorImage src={item.doctor.photo} size={56} />
				<div style={{ flexGrow: 1 }}>
					<h3 className='primary-dark fw-800'>{item.doctor.name} {item.doctor.lastName}</h3>
					<div className='d-flex align-items-center'><span>Calificación:</span>&nbsp;<Rating rating={item.rating} justify='flex-start' small /></div>
				</div>
				<div><span>{consultationTime(item.endDate)}</span></div>
			</div>
			<p className={styles.smallUppercase}>Motivo de la consulta</p>
			<p className='primary-dark mt-1'>{item.subject}</p>
		</div>
		<ChevronRight stroke={'#B3C1CC'} />
	</div>
}

const ConsultationSkeletonItem = () => {
	return <div className={styles.consultationCard} style={{ opacity: 0.5 }}>
		<div style={{ flexGrow: 1 }}>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 16, gap: 16 }}>
				<Skeleton width={56} height={56} radius='50%' />
				<div style={{ flexGrow: 1 }}>
					<Skeleton width={214} height={16} />
					<Skeleton width={176} height={14} />
				</div>
				<div><Skeleton width={76} height={12} /></div>
			</div>
			<Skeleton width={123} height={10} />
			<Skeleton width={'100%'} height={16} />
			<Skeleton width={'100%'} height={16} />
		</div>
	</div>
}


const Home = () => {
	const dispatch = useDispatch();
	const { consultations } = useSelector((state) => state.queue);
	const { current } = useSelector((state) => state.home);
	const { user } = useSelector((state) => state.user);
	const [showModal, setShowModal] = useState(false);
	const [selectedConsultation, setSelectedConsultation] = useState(null);

	const openModal = (item) => {
		setSelectedConsultation(item);
		setShowModal(true);
	}

	useEffect(() => {
		if (user._id) {
			dispatch(getConsultations(user._id));
		}
	}, [user])

	return (
		<section className={styles.homeGrid}>
			<section>
				{current === HOME_CARDS.HOME && <Consultation />}
				{current === HOME_CARDS.WAITING_ROOM && <WaitingRoom />}
				{current === HOME_CARDS.CONSULTATION_FINISHED && <Review />}
			</section>
			<section style={{ overflow: (consultations || []).length > 0 ? 'inherit' : 'hidden', paddingTop: 32 }} className='position-relative'>
				<h1 className="primary-dark mb-0">Mis consultas</h1>
				<p>Historial de consultas realizadas.</p>
				<div className={styles.consultationList} >
					{
						(consultations || []).length ? consultations.map(item => <ConsultationItem key={item._id} onClick={() => openModal(item)} item={item} />)
							: <>
								<ConsultationSkeletonItem />
								<ConsultationSkeletonItem />
								<ConsultationSkeletonItem />
								<ConsultationSkeletonItem />
								<ConsultationSkeletonItem />
								<div className={styles.noConsultations}>
									<LoginContainer title={"Estamos para ayudarle"} subtitle={"Aquí podrás ver el historial de consultas tan pronto \nrealices tus primeras consultas."} />
								</div>
							</>
					}
				</div>

			</section>
			<section>
				<Profile />
			</section>
			{showModal && <ModalConsultation consultation={selectedConsultation} setShowModal={setShowModal} />}
		</section>
	);
}

export default Home;