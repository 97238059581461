import React from "react";
import { formatNumberThousands } from "../../../helpers";
import DoctorImage from "../../utils/DoctorImage";
import styles from '../../css/patients.module.css';
import Tag from "../../utils/Tag";

const DoctorData = ({ doctor, vertical, tagClass = 'success', tagTitle = 'Disponible'}) => {
  return (<div>
    <div className={`${styles.doctorData} ${vertical ? styles.doctorDataVertical : ''}`}>
      <DoctorImage src={doctor.photo} size={96} />
      <div style={{ flexGrow: 1 }}>
        <h3 className='primary-dark fw-800'>{doctor.name} {doctor.lastName}</h3>
        <div><span>CM: {formatNumberThousands(doctor.cm)} | M.P.P.S: {formatNumberThousands(doctor.mpps)}</span>  </div>
      </div>
      { vertical && <div><Tag className={`bg-${tagClass}`} title={tagTitle}></Tag></div>}
    </div>
  </div >);
}

export default DoctorData;