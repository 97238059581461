import React, { useEffect } from 'react';
import styles from './modalsCSS/Modal.module.css';
import stylesModal from './modalsCSS/ModalFinishTurn.module.css';
import { X } from 'react-feather'
import { useDispatch } from 'react-redux';
import { resetFinishedConsultation } from '../../../actions/usersActions';

function ModalInfo({ setShowModal, showModal, title, body, onClose = () => {} }) {
  const dispatch = useDispatch();

  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      setShowModal({ ...showModal, modalConsultationFinished: false })
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown)
    return function cleanup() {
      dispatch(resetFinishedConsultation());
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown)
    }
  }, [])

  const closeModal = () => {
    setShowModal(false);
    onClose();
  }

  return (

    <div className={`${styles.modal} ${stylesModal.modalF} ${showModal.modalConsultationFinished && styles.animation}`} onClick={closeModal}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <div className={`${styles.modalHeader} p-0 pb-2 d-flex flex-row justify-content-between align-items-center`}>
          <h4 className={`${styles.modalTitle} text-start mb-0`}>
            <b>{title}</b>
          </h4>
          <div className='btn m-0 p-0'>
            <X
              color={'black'}
              style={{ verticalAlign: 'middle' }}
              onClick={closeModal}
            />
          </div>
        </div>

        <div className={`${styles.modalBody} p-0 py-3 d-flex justify-content-center flex-column`}>
          {body}
        </div>

        <div className={`${styles.modalFooter} p-0 pt-2 d-flex flex-row justify-content-end`}>
          <button
            className='btn btn-primary mx-2'
            onClick={closeModal}>
            Continuar
          </button>
        </div>

      </div>
    </div>
  );
}

export default ModalInfo;