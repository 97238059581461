import React, { useEffect } from 'react';
import styles from './modalsCSS/Modal.module.css';
import stylesModal from './modalsCSS/ModalFinishTurn.module.css';
import successImg from '../../../assets/img/success.png';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../../../actions/modalActions';
import Button from '../Button';
import { useNavigate } from 'react-router-dom';

function ModalPatients() {
  const modals = useSelector(state => state.modals);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeOnEscapeKeyDown = e => {
    if((e.charCode || e.keyCode) === 27){
      closeModal();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown)
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown)
    }
  }, [])

  const closeModal = () => {
    if (modals.next) navigate(modals.next);
    dispatch(hideModal());
  }

  return modals.show && <div className={`${styles.modal} ${stylesModal.modalF}`} onClick={closeModal}>
    <div className={styles.patientModal} onClick={e => e.stopPropagation()}>
      <div className='d-flex mb-4'>
        <img style={{margin: 'auto' }} src={successImg} alt='' />
      </div>
      <div className={styles.patientModalContent}>
        <h1 className="primary-dark">{modals.title}</h1>
        <p>{modals.text}</p>
      </div>

      <div className={`mt-4 d-flex justify-content-center`}>
        <Button text={modals.btnText || 'Continuar'} onClick={closeModal} />
      </div>
    </div>
  </div>
}

export default ModalPatients;