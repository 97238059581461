import React, {useEffect} from 'react';
import styles from './modalsCSS/Modal.module.css';
import stylesModal from './modalsCSS/ModalFinishTurn.module.css';
import {X} from 'react-feather'
import { useDispatch } from 'react-redux';
import { resetFinishedConsultation } from '../../../actions/usersActions';

function ModalConsultationFinished({setShowModal,showModal,title,body}) {
  const dispatch = useDispatch();
 
  const closeOnEscapeKeyDown = e => {
    if((e.charCode || e.keyCode) === 27){
      setShowModal({...showModal,modalConsultationFinished:false})
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown)
    return function cleanup(){
      dispatch(resetFinishedConsultation());
      document.body.removeEventListener('keydown',closeOnEscapeKeyDown)
    }
  },[])

      return (

      <div className={`${styles.modal} ${stylesModal.modalF} ${showModal.modalConsultationFinished && styles.animation}`} onClick={() => setShowModal(false)}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <div className={`${styles.modalHeader} d-flex flex-row justify-content-between`}> 
          <h4 className={`${styles.modalTitle} text-start`}>
              <b>{title}</b>
          </h4>
          <div className='btn m-0 p-0'>
            <X 
            color={'black'}
            
            style={{ verticalAlign: 'middle' }}
            onClick={() => setShowModal({...showModal,modalConsultationFinished:false})}
            />
          </div>
        </div>

        <div className={`${styles.modalBody} d-flex justify-content-center flex-column`}>
         <p className='my-0 text-start'>Haz completado con éxito la consulta médica con <strong className='text-dark'>{body}</strong>.</p><br />
         <p className='my-0 text-start'>Puedes continuar atendiendo los pacientes que se encuentran en la sala de espera.</p>
        </div>

        <div className={`${styles.modalFooter} d-flex flex-row justify-content-end`}>
          <button 
          className='btn btn-primary mx-2'
          onClick={() => setShowModal({...showModal,modalConsultationFinished:false})}>
           Continuar
          </button>
        </div>
          
      </div>
    </div>
    );
}

export default ModalConsultationFinished;