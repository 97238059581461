import React from 'react';
import Spinner from './Spinner';

const Button = ({ text, loading, disabled, className = '', type = 'button', btn = 'primary', onClick = () => {} }) => {
    return (
        <div className={`d-flex align-items-center justify-content-center `}>
            <button
                type={type}
                disabled={disabled}
                onClick={onClick}
                className={`btn btn-${btn} ${className}`}>
                {loading ? <Spinner /> : text}
            </button>
        </div>
    );
}

export default Button;