import React, {useEffect} from 'react'
import { getDataDoctor } from '../actions/usersActions';
import { useDispatch, useSelector } from 'react-redux';
import logoIMG from "../media/logo_secondary.svg";
import styles from './css/MyData.module.css';
import { Link } from 'react-router-dom';

const MyData = () => {

    const dispatch = useDispatch();
    const usersReducer = useSelector(state=>state.user);
    const {user} = usersReducer;
    const {name,
        lastName,
        ci,
        birthDate,
        gender,
        email,
        skill,
        numberPhone,
        cm,
        mpps
    } = user; 
    useEffect(() => {
        dispatch(getDataDoctor(user.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

return ( 
<section className='bg-primary'>
		<div className={styles.backPattern}>
		<div id="layoutAuthentication">
				<div id="layoutAuthentication_content">
                <main>
					<div className="container-xl px-4">
                    <div className="column justify-content-center">
                        <img src={logoIMG} alt="logo" className='my-5 d-flex mx-auto col-md-4'/>
                        <div className="col-lg-6 mx-auto mb-2">
                            {/* <!-- Basic login form--> */}
                        <div className="card shadow-lg border-0 rounded-lg mt-3">
                        <div className="card-header justify-content-center "><h3 className={"fw-normal my-4 "+styles.headerCustom}>{gender === 'M' ? 'Bienvenido Dr.' : 'Bienvenida Dra.'} {lastName}</h3></div>
                            <div className="card-body">
                            <p className={styles.pcustom}>Por favor confirme que estos datos son correctos</p>
                            <hr />
                            {/* <!-- Login form--> */}
                            <form>
                            {/* <!-- Form Row--> */}
                            <div className="row gx-3">
                                <div className="col-md-6">
                                    {/* <!-- Form Group (first name)--> */}
                                    <div className="mb-3">
                                        <label className="small" htmlFor="inputFirstName">Nombres</label>
                                        <p className={styles.textCustom}>{name}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {/* <!-- Form Group (last name)--> */}
                                    <div className="mb-3">
                                        <label className="small" htmlFor="inputLastName">Apellidos</label>
                                        <p className={styles.textCustom}>
                                            {lastName}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Form Row--> */}
                            <div className="row gx-3">
                                <div className="col-md-6">
                                    {/* <!-- Form Group (Ci)--> */}
                                    <div className="mb-3">
                                        <label className="small" htmlFor="inputPassword">Cédula de identidad</label>
                                        <p className={styles.textCustom}>{ci}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {/* <!-- Form Group (birthDate)--> */}
                                    <div className="mb-3">
                                        <label className="small" htmlFor="inputConfirmPassword">Fecha de nacimiento</label>
                                        <p className={styles.textCustom}>{birthDate}</p>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Form Group (Gender) --> */}
                            <div className="mb-3">
                                <label className="small" htmlFor="inputEmailAddress">Sexo</label>
                                <p className={styles.textCustom}>{gender === 'M' ? 'Masculino' : 'Femenino'}</p>
                            </div>
                            {/* <!-- Form Row--> */}
                            <div className="row gx-3">
                                <div className="col-md-6">
                                    {/* <!-- Form Group (email)--> */}
                                    <div className="mb-3">
                                        <label className="small" htmlFor="inputPassword">Correo electrónico</label>
                                        <p className={styles.textCustom}>{email}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {/* <!-- Form Group (numberphone)--> */}
                                    <div className="mb-3">
                                        <label className="small" htmlFor="inputConfirmPassword">Teléfono de contacto</label> 
                                        <p className={styles.textCustom}>{numberPhone}</p>  
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Form Row--> */}
                            <div className="row gx-1 col-11">
                                <div className="col-md-4">
                                    {/* <!-- Form Group (skill)--> */}
                                    <div className="mb-3">
                                        <label className="small" htmlFor="inputPassword">Especialidad</label>
                                        <p className={styles.textCustom}>{skill}</p>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    {/* <!-- Form Group (cm)--> */}
                                    <div className="mb-3">
                                        <label className="small" htmlFor="inputConfirmPassword">Colegio médico (C.M)</label> 
                                        <p className={styles.textCustom}>{cm}</p>  
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    {/* <!-- Form Group (mpps)--> */}
                                    <div className="mb-3">
                                        <label className="small">M.P.P.S</label> 
                                        <p className={styles.textCustom}>{mpps}</p>  
                                    </div>
                                </div>
                            </div>
                            <hr />
                            {/* <!-- Form Group (create account submit)--> */}
                            <div className='d-flex justify-content-between'>
                                <Link className={styles.linkTo} to="/">
                                    Regresar al inicio
                                </Link>
                                <Link className="btn btn-primary btn-block" to="/auth/pass">
                                    Confirmar
                                </Link>
                            </div>
                        </form>
                        </div>
                        </div>
                        </div>
                    </div>
					</div>
                </main>
            </div>    
		</div>
		</div>
</section>
    );
}
 
export default MyData;