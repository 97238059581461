import React, { useRef } from 'react';

import male from '../../assets/img/illustrations/profiles/profile-2.png';
import female from '../../assets/img/illustrations/profiles/profile-1.png';
import styles from '../css/patients.module.css';
import { Edit2 } from 'react-feather';
import { updatePatientImage } from '../../actions/usersActions';
import { useDispatch } from 'react-redux';

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000';

const DoctorImage = ({ staticSrc = '', src = '', background = '#0077C8', size, gender = 'F', editable = false, userId }) => {
  const realSrc = src.includes('http') ? src : `${BASE_URL}/${src}`;
  const imgInput = useRef();
  const dispatch = useDispatch();

  const editPhoto = (e) => {
    if (editable) imgInput.current.click()
  }

  const uploadPhoto = (e) => {
    dispatch(updatePatientImage(userId, e.target.files[0]));
  }



  return (<div className={editable ? styles.editable : ''} onClick={editPhoto}>
    <img
      style={{ borderRadius: '50%', background, width: size, height: size }}
      src={staticSrc || realSrc}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = (gender === 'F' ? female : male);
      }}
      alt="" />
    {editable && <>
      <input onChange={uploadPhoto} ref={imgInput} style={{ display: 'none' }} type="file" accept="image/*"/>
      <div className={styles.editPhoto}><Edit2 size={14} stroke='white' /></div>
    </>
    }
  </div>);
}

export default DoctorImage;