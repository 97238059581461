import { combineReducers } from 'redux';
import usersReducer from './usersReducer';
import queueReducer from './queueReducer';
import reportReducer from './reportReducer';
import modalReducer from './modalReducer';
import homeReducer from './homeReducer';

export default combineReducers({
    user: usersReducer,
    queue: queueReducer,
    reports: reportReducer,
    modals: modalReducer,
    home: homeReducer
});