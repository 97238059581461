import {
    SAVE_DESCRIPTION_SUCCESS,
    UPDATE_CONSULTATION,
    ADD_DOCTOR,
    ADD_PATIENT,
    REMOVE_PATIENT,
    UPDATE_CONSULTATION_HISTORY,
    CURRENT_DOCTOR,
    RESET_CURRENT_DOCTOR,
    LOGIN_DR_SUCCESS
} from '../types/index';

import clientAxios from '../config/axios';
import { setLoading } from './usersActions';

//Save description for the consultation
export function ReduxSaveDescription(data){
 return async dispatch => {
   try {
    dispatch(setLoading());
    const response = await clientAxios.post(`api/consultation/pt/${data.id}`,{description:data.description});
    dispatch({
        type:SAVE_DESCRIPTION_SUCCESS,
        payload:{
         msg:response.data.msg,
         consultation: response.data.consultation
        }
    });  
   } catch (error) {
       console.log(error)
      dispatch(setLoading());
   };
 };
}

export function updateConsultation(id, userId, data){
 return async dispatch => {
   try {
    dispatch(setLoading());
    const response = await clientAxios.put(`api/consultation/pt/${id}`, data);
    dispatch({
        type:UPDATE_CONSULTATION,
        payload:{
         msg:response.data.msg,
         consultation: response.data.consultation
        }
    });  
    dispatch(getConsultations(userId));
   } catch (error) {
       console.log(error)
      dispatch(setLoading());
   };
 };
}

export function getConsultation(id){
 return async dispatch => {
   try {
    dispatch(setLoading());
    const response = await clientAxios.get(`api/consultation/pt/${id}`);
    dispatch({
        type:UPDATE_CONSULTATION,
        payload:{
         msg:response.data.msg,
         consultation: response.data.consultation
        }
    });  
   } catch (error) {
       console.log(error)
      dispatch(setLoading());
   };
 };
}

export function getConsultations(id){
 return async dispatch => {
   try {
    dispatch(setLoading());
    const response = await clientAxios.get(`api/consultation/pt?patient=${id}&endDate=!null&doctor=!null`);
    dispatch({
        type:UPDATE_CONSULTATION_HISTORY,
        payload:{
         msg:response.data.msg,
         consultations: response.data.consultation
        }
    });  
   } catch (error) {
       console.log(error)
      dispatch(setLoading());
   };
 };
}

//Adding patient
export function add_patient(patients){
    return function(dispatch){
        try {
            dispatch({
                type:ADD_PATIENT,
                payload:patients
            });
        } catch (error) {
          console.log(error)  
        };
    };
};
export function add_doctor(doctors){
    return function(dispatch){
        try {
            dispatch({
                type:ADD_DOCTOR,
                payload:doctors
            });
        } catch (error) {
          console.log(error)  
        };
    };
}
//remove a patient
export function remove_patient(patients,patient){
    return function(dispatch){
        try {
           dispatch({
            type:REMOVE_PATIENT,
            payload:patients.filter(e => e.id !== patient.id)
           }) 
        } catch (error) {
            console.log(error)
        }
    }
}

export function setCurrentDoctor(doctor){
    return dispatch => {
        dispatch({
            type: CURRENT_DOCTOR,
            payload: doctor
        })
    }
};
export function resetCurrentDoctor(){
    return dispatch => {
        dispatch({ type: RESET_CURRENT_DOCTOR })
    }
};
