import React, { useEffect, useRef, useState, useContext } from "react";
import useSocket from "../hooks/useSocket";
import { Mic, Video, Phone, MicOff, VideoOff } from 'react-feather';
import { useSelector } from 'react-redux';

import styles from './css/StreamArea.module.css';
import useMediaStream from "../hooks/useMediaStream";

function StreamArea({ onCandidate, onWatcher, onAnswer, onOffer, onEndStream, patients }) {
  const { currentPatient, user } = useSelector(state => state.user);
  const { doctor } = useSelector(state => state.queue);
  const [socket, socketId] = useSocket();
  const { stream, hasAudio, hasVideo, setAudio, setVideo, stopStream } = useMediaStream();
  var video = useRef(null)
  var localMediaStream = useRef(null)

  useEffect(() => {
    if (stream) {
      localMediaStream.current.srcObject = stream;
      socket.on("watcher", (id) => {
        onWatcher(id, socket, localMediaStream);
      });
    }
    socket.on("answer", onAnswer);
    socket.on("offer", (id, description) => {
      onOffer(id, description, socket, video)
    });
    socket.on("candidate", onCandidate);
    socket.on("end-stream", () => {
      stopStream();
      onEndStream();
    });
    return () => {
      socket.off('answer');
      socket.off('watcher');
      socket.off('offer');
      socket.off('candidate');
      socket.off('end-stream');
    }
  }, [stream]);

  useEffect(() => {
    console.log('Joining stream')
    const joinStream = () => {
      if (!video.current.srcObject) {
        console.log('Trying to connect with', patients ? doctor.id : currentPatient.id);
        socket.emit('join-stream', patients ? doctor.id : currentPatient.id);
      } else {
        console.log('Connected');
      }
    }

    const timeout = setInterval(joinStream, 5000);
    joinStream()

    return () => {
      clearInterval(timeout);
    };
  }, [video, user, currentPatient]);

  async function endStream() {
    socket.emit("end-stream", { id: currentPatient.id, consultation: currentPatient.consultation._id });
    stopStream();
    onEndStream()
  }

  return (
    <div className={styles.videoArea}>
      <div>DEBUG</div>
      <div>USER ID: {user.id}</div>
      <div>Patient ID: {currentPatient.id}</div>
      <div>Doctor ID: {doctor.id}</div>
      <div className={`${styles.videoScreen} d-flex mb-10`}>
        <video ref={localMediaStream} autoPlay={true} className={`${styles.localVideoScreen} ${patients ? styles.patients : ''}`} id="local-video" playsInline muted></video>
        <video ref={video} autoPlay={true} className={styles.remoteVideoScreen} id="remote-video" playsInline></video>
        <div className={styles.videoIcons}>
          <div className={`${styles.videoIcon} ${!hasAudio ? styles.hangup : ''}`} onClick={() => setAudio(!hasAudio)}>
            {hasAudio ? <Mic stroke="white" /> : <MicOff stroke="white" />}
          </div>
          <div className={`${styles.videoIcon} ${!hasVideo ? styles.hangup : ''}`} onClick={() => setVideo(!hasVideo)}>
            {hasVideo ? <Video stroke="white" /> : <VideoOff stroke="white" />}
          </div>
          <div className={`${styles.videoIcon} ${styles.hangup}`} onClick={endStream}>
            <Phone stroke="white" />
          </div>
        </div>
      </div>
    </div>
  )
}

StreamArea.defaultProps = {
  onWatcher: () => { },
  onOffer: () => { },
  onEndStream: () => { }
}

export default StreamArea;
