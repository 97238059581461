import React, {useEffect} from 'react'
import styles from './modalsCSS/Modal.module.css';
import stylesModal from './modalsCSS/ModalStopTurn.module.css';
import useSocket from '../../../hooks/useSocket';

function ModalStopTurn({setShowModal,showModal, title,body}) {
  const [socket] = useSocket();
	const resumeTurn = () => {
    setShowModal({...showModal,modalPause:false});
		socket.emit('resumeTurn');
	}

  const closeOnEscapeKeyDown = e => {
    if((e.charCode || e.keyCode) === 27){
      setShowModal({...showModal,modalPause:false})
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown)
    return function cleanup(){
      document.body.removeEventListener('keydown',closeOnEscapeKeyDown)
    }
  },[])

      return (

      <div className={`${styles.modal} ${stylesModal.modalS} ${showModal.modalPause && styles.animation}`}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <h4 className={styles.modalTitle}>
              <b>{title}</b>
          </h4>
        </div>

        <div className={`${styles.modalBody} ${stylesModal.contentS}`}>
          {body}
        </div>

        <div className={`${styles.modalFooter} ${stylesModal.footerS}`}>
            <button 
            onClick={resumeTurn}
            className='btn btn-primary'
            >
              Reanudar turno
            </button>
        </div>
          
      </div>
    </div>
    );
}

export default ModalStopTurn
