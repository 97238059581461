import { useEffect, useState } from 'react';

export default function useMediaStream() {
  const [hasAudio, setAudio] = useState(true);
  const [hasVideo, setVideo] = useState(true);
  const [stream, setStream] = useState();

  useEffect(() => {
    if (stream) {
      stream.getAudioTracks().forEach(track => track.enabled = hasAudio);
      stream.getVideoTracks().forEach(track => track.enabled = hasVideo);
    }
  }, [hasAudio, hasVideo, stream])

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then(setStream)
      .catch(console.log);
  }, [])

  const stopStream = () => {
    if (stream) {
      stream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  }

  return { stream, setStream, hasAudio, hasVideo, setAudio, setVideo, stopStream };
}
