import StreamArea from './StreamArea';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { finishConsultation, setLastPatient, getPatient } from '../actions/usersActions';
import { ExternalLink } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import styles from './css/Dash.module.css'
import imgLogo from '../media/logo_header.png';
import { formatNumberThousands, getDate } from '../helpers';
import useMediaStream from '../hooks/useMediaStream';
import VideoCall from './VideoCall';

const { RTCPeerConnection } = window;
let peerConnections = {};
const config = {
  iceServers: [
    {
      urls: ["stun:stun.l.google.com:19302"]
    }
  ]
};

let watcherInterval;
let offerInterval;

const restartPeer = (peerConnection) => {
  switch (peerConnection.iceConnectionState) {
    case "disconnected":
    case "failed":
      peerConnection.restartIce()
  }
}

function Room() {
  useMediaStream();
  const { currentPatient } = useSelector(state => state.user);
  const [patient, setPatient] = useState({ insurance: {} });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    patientData();
  }, [])

  const patientData = async () => {
    const patient = await getPatient(currentPatient.id);
    setPatient(patient.data.patient);
  }

  const onWatcher = (id, socket, video) => {
    console.log("Watcher joining", id)
    const peerConnection = new RTCPeerConnection(config);
    peerConnections[`${id}-offer`] = peerConnection;

    if (!video.current.srcObject) throw new Error('No local media detected');

    let stream = video.current.srcObject;

    peerConnection.addStream(stream);
    stream.getTracks().forEach((track) => {
      return stream.addTrack(track)
    });

    peerConnection.onicecandidate = event => {
      if (event.candidate) {
        console.log("Room Candidate", currentPatient.id)
        socket.emit("candidate", currentPatient.id, { message: event.candidate, role: 'watcher' });
      }
    };

    peerConnection
      .createOffer({ iceRestart: true })
      .then(sdp => peerConnection.setLocalDescription(sdp))
      .then(() => {
        console.log("Room Offering", currentPatient.id)
        socket.emit("offer", currentPatient.id, peerConnection.localDescription);
      });

    peerConnection.onconnectionstatechange = function (event) {
      switch (peerConnection.connectionState) {
        case "disconnected":
        case "failed":
          /*
          peerConnection.close();
          delete peerConnections[`${id}-offer`];
          onOffer(id, description, socket, video) // Reconnects
          */
          break;
      }
    }

    peerConnection.oniceconnectionstatechange = function () {
      console.log('ICE state: ', peerConnection.iceConnectionState);
      switch (peerConnection.iceConnectionState) {
        case "disconnected":
        case "failed":
          watcherInterval = setInterval(restartPeer(peerConnection), 1000);
          break;
        default:
          if (watcherInterval) clearInterval(watcherInterval)
      }
    }
  }

  const onOffer = (id, description, socket, video) => {
    console.log("Stream accepted", id)
    const peerConnection = new RTCPeerConnection(config);
    peerConnections[`${id}-watcher`] = peerConnection;
    console.log(peerConnections);
    peerConnection
      .setRemoteDescription(description)
      .then(() => peerConnection.createAnswer())
      .then((sdp) => peerConnection.setLocalDescription(sdp))
      .then(() => {
        socket.emit("answer", currentPatient.id, { message: peerConnection.localDescription, role: 'offer' });
      });
    peerConnection.onaddstream = (event) => {
      video.current.srcObject = event.stream;
      // IMPORTANT: USER MUST CLICK TO BE ABLE TO SEE THE VIDEO
      video.current.onloadedmetadata = function (e) {
        video.current.play();
      };
    };

    peerConnection.oniceconnectionstatechange = function () {
      console.log('ICE state: ', peerConnection.iceConnectionState);
      switch (peerConnection.iceConnectionState) {
        case "disconnected":
        case "failed":
          offerInterval = setInterval(restartPeer(peerConnection), 1000);
          break;
        default:
          if (offerInterval) clearInterval(offerInterval)
      }
    }

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        console.log("Room OFFER Candidate", currentPatient.id)
        socket.emit("candidate", currentPatient.id, { message: event.candidate, role: 'offer' });
      }
    };

    peerConnection.onconnectionstatechange = function (event) {
      switch (peerConnection.connectionState) {
        case "disconnected":
        case "failed":
          /*
          console.log('closing')
          peerConnection.close();
          delete peerConnections[`${id}-watcher`];
          video.current.srcObject = null
          */
          break;
      }
    }
  }

  const onCandidate = (id, candidate) => {
    peerConnections[id].addIceCandidate(new RTCIceCandidate(candidate));
  }

  const onAnswer = (id, description) => {
    peerConnections[id].setRemoteDescription(description);
  }

  const onEndStream = () => {
    for (const id in peerConnections) peerConnections[id].close()
    dispatch(setLastPatient(currentPatient));
    dispatch(finishConsultation());
    navigate('/dashboard');
  }
  return (
    <div className="container-xxl container-full px-4 pt-5">
      <div className="row">
        <div className="col-md-4 col-sm-12 mb-4">
          {/* <!-- Dashboard info widget 1--> */}
          <div className="card border-start-lg h-100">
            <div className="card-body d-flex flex-column">
              <div className={' d-flex flex-row align-items-center'}>
                {/* patient info */}
                <div>
                  <div className={'d-flex md-flex-row mb-3'}>
                    {/* photo */}
                    <div className={styles.imgPatient}>
                      {currentPatient.patient}
                    </div>
                    {/* name */}
                    <div className={'mx-3'}>
                      <h3 className={'text-primary fw-600'}>
                        {currentPatient.name + ' ' + currentPatient.lastName}
                      </h3>
                      <h5 className={'fw-600 text-dark'}>{currentPatient.nationality}-{formatNumberThousands((currentPatient.ci || '').toString())}</h5>
                    </div>
                  </div>

                </div>
              </div>
              <div>
                <h5 className={'fw-400 text-gray-600'}>Fecha de nacimiento</h5>
                <h4 className={'fw-600 text-dark mb-3'}>{getDate(patient.birthDate)}</h4>
              </div>
              <div>
                <h5 className={'fw-400 text-gray-600'}>Sexo</h5>
                <h4 className={'fw-600 text-dark mb-3'}>{patient.gender === 'M' ? 'Masculino' : 'Femenino'}</h4>
              </div>
              {patient.insurance && <div>
                <h5 className={'fw-400 text-gray-600'}>Seguro</h5>
                <h4 className={'fw-600 text-dark mb-3'}>{patient.insurance.name}</h4>
              </div>}
              <div>
                <h5 className={'fw-400 text-gray-600'}>Email</h5>
                <h4 className={'fw-600 text-dark mb-3'}>{patient.email}</h4>
              </div>
              <div>
                <h5 className={'fw-400 text-gray-600'}>Teléfono de contacto</h5>
                <h4 className={'fw-600 text-dark mb-4'}>{patient.numberPhone}</h4>
              </div>

              {/*
              <button className="btn btn-primary p-3 mb-5 align-self-center align-items-center justify-content-center d-flex">
                <div>Abrir historia médica&nbsp;</div>
                <ExternalLink width={16}/>
              </button>
                */}
              <div>
                <h5 className={'fw-600 text-primary'}>Motivo de consulta</h5>
                <h4 className={'fw-600 text-dark mb-3'}>{currentPatient.consultation.subject}</h4>
              </div>
              <img src={imgLogo} className={styles.roomLogo} alt='' />
            </div>
          </div>
        </div>
        <div className="col-md-8 col-sm-12 mb-4 position-relative">
          <VideoCall callId={currentPatient.id} onEndStream={onEndStream} isCaller={true} />
          {/*
          <StreamArea
            onOffer={onOffer}
            onAnswer={onAnswer}
            onWatcher={onWatcher}
            onEndStream={onEndStream}
            onCandidate={onCandidate} />
          */}
        </div>
      </div>
    </div>
  )
}

export default Room;
